.cart-price {

  color: $strong-font-color;

  &--right {
    text-align: right;
  }

  &__item {
    display:     inline-block;
    font-weight: 700;

    & + & {
      margin-left: 0.3em;
    }

    &--old {
      position: relative;

      //font-size: 0.9em;
      color:    $attention-color;

      &::before {
        content:    "";
        position:   absolute;
        top:        0;
        right:      0;
        bottom:     0;
        left:       0;
        margin:     auto;

        background: currentColor;
        height:     em(1px);

      }
    }

    &--discount {
      color: $attention-color;
    }
  }

  /*&__main {
    @include clearfix;
    display:        inline-block;
    vertical-align: middle;
    font-size:      1.2rem;
    font-weight:    bold;
    white-space:    nowrap;
    color:          #000;

    &--vertical {
      width:       100%;
      margin-left: 0;
    }

    &--small {
      font-size: 1rem;
    }

    &--lg {
      font-size: 1.5rem;
    }

    &-value {
      float: left;
    }

    &-cur {
      float: left;
    }
  }

  &__addition {
    @include clearfix;
    display:        inline-block;
    vertical-align: middle;
    margin-left:    5px;
    font-size:      0.9rem;
    color:          $secondary-font-color;

    &-item {
      float: left;

      &:before {
        content: '/';
        float:   left;
        margin:  1px 5px 0 5px;
      }
    }

    &-value {
      float: left;
    }

    &-cur {
      float: left;
    }
  }*/

  &__cur {
    font-size:      0.8em;
    vertical-align: middle;
  }
  &__coins {
    vertical-align: middle;
  }
  &__value {
    font-size:      1em;
    vertical-align: middle;
  }

}