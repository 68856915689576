/* bootstrap default styles cancel */
label {
  font-weight: normal;
  display: block;
  max-width: auto;
  margin-bottom: 0;
}


.form {
  $label-hor-width-sm: 60px;
  $label-hor-width: 100px;
  $label-hor-width-lg: 150px;
  $require-mark-color: #E89B88;
  /* breakpoint from which vertical labels translate to horizontal */
  $hor-to-vert-breakpoint: $screen-sm-min;

  &__messages {
    padding-top: 20px;
  }

  &__field {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Form field label */
  &__label {
    padding-bottom: 8px;
    margin-right: em(10);
    color: $base-font-color;

    &:empty {
      padding-bottom: 0;
    }

    &--required::after {
      content: '*';
      font-size: 16px;
      position: relative;
      left: 2px;
      color: $require-mark-color;
    }
  }

  /* Form field mark if field is required */

  /* Form field inner control */
  &__inner {
    &--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__desc {
    line-height: 1.6;
    color: $secondary-font-color;
  }

  &__info {
    font-size: 0.9em;

    position: relative;
    margin-top: 3px;

    &--error {
      color: #E89B88;
    }

    &--help {
      color: $secondary-font-color;
    }
  }

  &__link {
    @include link-main;
  }

  &__checkbox {
    display: table;

    &:not(:first-child) {
      padding-top: 10px;
    }

    &-field {
      display: table-cell;
      width: 20px;
      vertical-align: middle;
      position: relative;
    }

    &-inner {
      display: table-cell;
      vertical-align: middle;
    }

    &-title {
      color: #000;
      line-height: 1.1;

      &--disabled {
        color: $secondary-font-color;
      }
    }

    &-select {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  &__select {
    @extend .form-control;
  }

  &__capcha {
    display: inline-block;
    height: 34px;
    border: 1px solid $base-border-color;
    overflow: hidden;

    @media (min-width: $screen-sm-min) {
      border-left: 0;
    }

    img {
      max-height: 34px;
    }
  }

  @media(min-width: $hor-to-vert-breakpoint) {
    &__field {
      display: flex;
      align-items: center;
      width: 100%;
    }
    &__label {
      //display:        table-cell;
      width: $label-hor-width;
      padding-bottom: 0;
      //vertical-align: top;
      //padding-top:    8px;

      &--top {
        align-self: flex-start;
      }
    }
    &__field--lg &__label {
      width: $label-hor-width-lg;
    }
    &__field--static &__label {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__inner {
      //display:        table-cell;
      //vertical-align: top;
      //width:          calc(100% - #{$label-hor-width});
      flex-grow: 1;
    }
    &__field--lg &__inner {
      width: calc(100% - #{$label-hor-width-lg});

    }
  }
}
