.footer {

  font-size: rem(12);
  color: $footer-text-color;

  &__flex-container {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    margin: {
      left: - $footer-col-vertical-gutter;
      right: - $footer-col-vertical-gutter;
    }

    &--center {
      justify-content: center;
    }

    & + & {
      margin-top: 20px;
      padding-top: 15px;
      border-top: 1px solid $footer-border-color;
    }
  }

  &__col {
    padding: 0 $footer-col-horizontal-gutter/2;
    margin-bottom: $footer-col-vertical-gutter;
  }

  &__title {
    display: block;
    font-size: rem(16px);
    font-weight: bold;
    color: $footer-title-color;
    text-transform: uppercase;
    text-decoration: none;


    &[href] {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__inner + &__title {
    margin-top: rem(15px);
  }

  &__inner {
    display: block;
    color:   $inverse-secondary-font-color;

    &--center {
      text-align: center;
    }

    & + & {
      margin-top: 10px;
    }
  }
  &__title + &__inner {
    margin-top: 15px;
  }

  &__items {

  }

  &__item {
    & + & {
      margin-top: 5px;
    }
  }

  &__link {
    @include link-inverse-main();
    color: $inverse-secondary-font-color;
  }

  @media (min-width: $screen-sm-min) {
  }
  @media (min-width: $screen-md-min) {

  }
  @media (min-width: $screen-lg-min) {
    &__flex-container {
      flex-flow: nowrap;
    }
  }

}