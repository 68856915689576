.overlay {
  $arrow-size: 8px;
  padding:    5px 0;
  text-align: left;

  border:     1px solid $overlay-border-color;
  background: $overlay-bg-color;
  box-shadow: 0 3px 4px $overlay-shadow-color;

  &--arrow {
    margin-top: $arrow-size;
    @include ghost-triangle-with-border($arrow-size, $overlay-bg-color, $overlay-border-color, 1px, top, left, 15px);
  }
  &--right-arrow {
    margin-top: $arrow-size;
    @include ghost-triangle-with-border($arrow-size, $overlay-bg-color, $overlay-border-color, 1px, top, right, 15px);
  }

  &__item {
    position: relative;

    &:hover {
      color: $overlay-link-hover-color;
    }
  }

  &__link {
    display:         block;
    padding:         5px 30px 5px 10px;
    white-space:     nowrap;
    color:           $overlay-link-color;
    text-decoration: none;

    transition:      color $theme-effects-time;

    &[href]:hover {
      color: $overlay-link-hover-color;
    }
  }
  &__item--active > &__link {
    background: $overlay-link-background-hover;
  }

  &__icon {
    position: absolute;
    display:  block;
    right:    10px;
    top:      0;
    bottom:   0;
    width:    0.7em;
    height:   0.7em;
    margin:   auto;
  }

  &__arrow {
    position:   absolute;
    right:      5px;
    top:        50%;
    margin-top: -6px;
  }

  &__drop {
    position:            absolute;
    top:                 0;
    left:                100%;

    white-space:         nowrap;

    opacity:             0;
    visibility:          hidden;
    transition-property: visibility, opacity;
    transition-duration: $theme-effects-time;

    //Element Modifiers
    &--rtl {
      right: 100%;
      left:  auto;
    }
  }
  &__item:hover > &__drop {
    visibility: visible;
    opacity:    1;
  }

}