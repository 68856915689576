.board-nav {

  &__item {
    @include product-plate-styles();
    display: block;
    padding: rem(15px);

    border: 1px solid $base-border-color;
    text-decoration: none;
  }
  &__photo {
    position:       relative;

    display:        block;
    width:          100%;
    padding-bottom: $product-photo-height-to-width-ratio;
  }
  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  &__title {
    margin-top: 1em;
    height: 34px;
    overflow: hidden;

    font-size: rem(13px);
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $strong-font-color;
    text-decoration: none;
  }
}