.product-kit {
  $col-top-gutter: 2em;
  $col-left-gutter: 1em;

  $item-top-gutter: 1em;
  $item-left-gutter: 1em;

  // layouts
  &__row {
    display:         flex;
    flex-flow:       column nowrap;
    justify-content: flex-start;
    align-items:     flex-start;

    margin-top:      - $col-top-gutter;
    margin-left:     - $col-left-gutter;

    &:not(.slick-clide):nth-child(n+1) {
      display: none;
    }

    @at-root .slick-initialized &.slick-slide {
      display: flex;
    }
  }
  &__col {
    display:         flex;
    justify-content: flex-start;
    align-items:     flex-start;
    flex-direction:  column;
    flex-wrap:       wrap;

    width:           100%;

    padding-top:     $col-top-gutter;
    padding-left:    $col-left-gutter;

    margin-top:      - $item-top-gutter;
    margin-left:     - $item-left-gutter;

    &--nowrap {
      flex-wrap: nowrap;
    }
  }
  &__item {
    //flex-basis: 33%;

    padding-top:  $item-top-gutter;
    padding-left: $item-left-gutter;

    &--center {
      align-self: center;
    }
  }

  // elements
  &__icon {
    display: block;
    color: $third-font-color--light;
    width:   em(20);
    height:  em(20);
  }

  &__price {
  }
  &__discount {
    margin-top: 0.5em;
    font-weight: 700;
    color: $attention-color;
  }
  &__btn {
    margin-top: 0.5em;
  }

  @media (min-width: $screen-md-min) {
    &__row {
      flex-direction: row;
      align-items:    center;
    }
    &__col {
      width:          auto;
      flex-direction: row;
      align-items:    center;
    }
  }

}