.info-table {
  width: 100%;

  &__header {
    border-bottom: 1px solid $base-border-color;
    text-align: left;
    text-transform: uppercase;
  }
  &__row {
    &:not(:last-child) {
      border-bottom: 1px solid $base-border-color;
    }
  }

  &__col {
    vertical-align: middle;
    padding: 10px 10px;
  }

  &__value {
    font-weight: 700;
  }

  &__link {
    @include link_main;
  }

}