.autocomplete {
  $frame-z-intdex: $z-index-m;
  $frame-bg: #FFF;
  $item-bg-hover: #F2F2F2;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: $autocomplete-width;
  max-width: rem($autocomplete-max-width);

  z-index: $z-index-xl;

  visibility: visible;
  opacity: 1;

  transition-property: visibility, opacity;
  transition-duration: $theme-effects-time;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &__icon {
    display: block;
  }

  &__element {
    height: 100%;

    .input-group,
    .form-control,
    .input-group-btn{
      height: 100%;
    }

    .form-control {
      padding: 0 rem(10px);
      border: 0;

      &:focus {
        box-shadow: none;
      }
    }
    box-shadow: 0 0 5px 0 $autocomplete-shadow-color;
  }

  &__input {
    font-size: 1.144rem;
    @media (min-width: $screen-md-min){
      font-size: 1rem;
    }
  }

  &__frame {
    background: $frame-bg;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: $frame-z-intdex;
    border: 1px solid $autocomplete-border-color;
    background: $autocomplete-bg-color;
    box-shadow: 0 3px 4px $autocomplete-shadow-color;
  }

  &__item {
    display: block;
    text-decoration: none;
    padding: 10px;
    color: $base-font-color;

    transition: all $theme-effects-time;

    &:hover {
      background: $item-bg-hover;
    }
  }

  &__product {
    @include clearfix;
    font-size: 0.9rem;

    &-photo {
      float: left;
      padding-right: 10px;
    }

    &-info {
      overflow: hidden;
    }

    &-title {
      margin-bottom: 5px;
    }

    &-brand {
      display: inline;
      color: $secondary-font-color;
    }

    &-option {
      color: $secondary-font-color;
    }
  }

  &__message {
    padding: 20px;

    &--noitems {
    }
  }
}