/*
--------------------------------------------------
Catalog sidebar menu
--------------------------------------------------
*/
.sidebar-nav {

  padding: em($sidebar-top-gutter) 0 em($sidebar-bottom-gutter);
  background: $sidebar-background;

  &__outer-list {
  }
  &__outer-item {
    & + & {
      margin-top: em(20);
    }
  }

  &__header {
    margin-bottom: em(10);
    padding: 0 em($sidebar-horizontal-gutter);
  }
  &__title {
    color: $sidebar-title-color;
    font-weight: 700;
  }

  //Elements
  &__list {
    font-size: em(12);
  }
  &__item {
  }

  &__link {
    @include link-second();
    display: block;
    padding: em(5, 12) em($sidebar-horizontal-gutter, 12);

    &--active {
      background: $theme-secondary-color--dark;
      cursor: default;

      &:hover {
        color: $base-second-link-color;
      }
    }
  }
  &__count {
    color: $secondary-font-color;
  }
}