.svg-icon {
  display:    block;
  max-width:  100%;
  max-height: 100%;

  fill:       currentColor;

  &--flip-vertical {
    transform: scaleY(-1);
  }
  &--flip-horizontal {
    transform: scaleX(-1);
  }

}