.tooltip {
  $ico-size: 1em;
  $message-width: 300px;
  $message-bg: $theme-main-bg;
  $message-border-color: $base-border-color;
  $message-arrow-size: 10px;
  $message-arrow-offset: 15px;

  position: relative;
  cursor: pointer;

  &__icon-wrapper {
    position:      relative;

    display:       inline-block;
    width:         $ico-size;
    height:        $ico-size;

    background:    $info-color;
    border-radius: 50%;
    color:         $strong-font-color;
  }
  &__icon {
    @include absolute-center-margin;
    display: block;
    width:   60%;
    height:  60%;
  }
  &__label {
    border-bottom: 1px dashed currentColor;
  }
  &__message {
    @include ghost-triangle-with-border($message-arrow-size, $message-bg, $message-border-color, 1px, bottom, left, $message-arrow-offset);

    position:    absolute;
    display:     block;
    z-index:     $z-index-l;
    bottom:      calc(100% + #{$message-arrow-size});
    left:        -$message-arrow-offset - $message-arrow-size/2;

    padding:     1em;
    font-weight: initial;
    background:  $message-bg;
    border:      1px solid $message-border-color;
    width:       $message-width;

    visibility:  hidden;
    opacity:     0;

    transition:  all $theme-effects-time;
  }
  &:hover &__message {
    visibility: visible;
    opacity:    1;
  }
}