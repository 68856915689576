.post {

  $cell-border-width: 2px;

  &--small {
    font-size: 0.9rem;
  }

  &__image {
    display: block;
    margin-bottom: 5px;
  }

  /* Display photo to the left on large devices */
  @media (min-width: $screen-sm-min) {

    display: table;

    //firefox max-width for table-cell hack
    table-layout: fixed;

    &__image {
      display: table-cell;
      vertical-align: top;
      padding-right: 30px;
      width: 30%;
      margin-bottom: 0;
    }

    &__inner {
      display: table-cell;
      vertical-align: top;
    }
  }

  &__date {
    display: block;
    color: $secondary-font-color;
    padding-bottom: 3px;
  }

  &__title {
    font-size: rem(18px);
    text-transform: uppercase;
    text-decoration: underline;
  }
  &__subtitle {
    margin-top: 0.5em;
  }

  &__desc {
    padding-top: 5px;
  }

  &__readmore {
    @include link-main;
    display: block;
    margin-top: 5px;
    white-space: nowrap;

    &:after {
      content: '>';
      margin-left: 5px;
    }

  }

  &__expire {
    display: block;
    padding: 5px 0;
    color: #C00;
  }

  &__footer {
    margin-top: rem(20);
  }
  &__footer-row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    margin-left: - $cell-border-width;

    &--v-center {
      align-items: center;
    }
    & + & {
      margin-top: 1em;
    }
  }

  &__footer-cell {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    padding: rem(8px) rem(15px);
    background: $theme-secondary-bg;
    border-top: $cell-border-width solid $theme-main-bg;
    border-left: $cell-border-width solid $theme-main-bg;





  }

  &__footer-icon {
    display: inline-block;
    vertical-align: middle;
    height: em(15px);
    width: em(15px);

    color: $theme-accent-color;
  }

  &__footer-text {
    display: inline-block;
    vertical-align: middle;
  }
  &__footer-icon + &__footer-text {
    padding-left: em(10);
  }
  &__text-elem {
    & + & {
      margin-left: 0.5em;
    }
  }

}