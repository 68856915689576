.product-thumb {
  text-align: center;

  &__photo {
    max-width: 200px;
    margin:    0 auto;
  }

  &__title {
    padding-top: 5px;
    font-size:   0.9rem;

    &-link {
      @include link-main;
    }
  }

  &__price {
    padding-top: 5px;
  }

}