.site-info {
  font-size: rem(12);
  display: table;

  &__cell {
    display: table-cell;
    vertical-align: bottom;
    padding: rem(5);
  }

  &__title {
    display: inline-block;
    vertical-align: bottom;

    text-transform: uppercase;
    font-size: 1.2em;
    color: $site-info-title-color;
    font-weight: bold;

    &:not(:last-child) {
      margin-right: em(10);
    }
  }

  &__desc {
    display: inline-block;
    vertical-align: bottom;
    color: $site-info-desc-color;
  }

  &__link {
    @include link-main;
  }
}