// product-action is about wishlist and compare
.product-actions {

  $item-top-gutter: 0.5em;
  $item-left-gutter: 1em;

  color: $theme-accent-color;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    margin-top: - $item-top-gutter;
    margin-left: - $item-left-gutter;

    &--center {
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    margin-top: $item-top-gutter;
    margin-left: $item-left-gutter;
  }

  &__icon {
    display: block;
    width: 1.5em;
    height: 1em;
  }

  &__link {
    margin-left: em(5);
  }

}