.brands-widget {

  $arrow-size: 42px;

  padding-bottom: 20px;
  border-bottom: 1px solid $base-border-color;

  &__header {
    position: relative;
    margin-bottom: rem(15px);
    text-align: center;

    &::after {
      content: "";

      position: absolute;
      top: 0;
      bottom: 0;

      display: block;
      width: 100%;
      height: 1px;
      margin: auto;
      z-index: -1;

      background: $base-border-color;
    }
  }

  &__title {
    @include one-line-ellipsis();

    display: inline-block;

    padding: 0 15px;

    font-size: rem(16);
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1;
    background: $theme-main-bg;

    color: $widget-primary-title-color;
  }

  &__inner {
    position: relative;
    padding: 0 40px;
  }
  &__item {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 50%;
    overflow: hidden;
  }
  &__img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    max-width: 100%;
    max-height: 100%;
  }
  &__label {
    @include absolute-center-translate();
    @include one-line-ellipsis();
    text-align: center;
    font-size: 20px;
    color: black;
  }
  &__link {
    @include link-decoration-only();
  }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;

    display: block;
    cursor: pointer;
    margin: auto;
    height: rem($widget-primary-arrow-size/4*3);
    width: rem($widget-primary-arrow-size/4*3);


    border: 1px solid $base-border-color;
    background: $widget-primary-arrow-background;
    color: $widget-primary-arrow-color;

    transition: all $theme-effects-time;

    &:active {
      transform: scale(1.1);
      //animation: jumpUp $theme-effects-time ease-out 1;
    }

    &--prev {
      left: -10px;
      right: auto;
    }
    &--next {
      right: -10px;
      left: auto;
    }

    /* styles for disabled arrow, when slick carousel infinite is disabled */
    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
      cursor: default;
    }

    &:not(.slick-disabled):hover {
      color: $widget-primary-arrow-color-hover;
      background: $widget-primary-arrow-background-hover;
      border-color: $widget-primary-arrow-background-hover;
    }
  }

  &__icon {
    @include absolute-center-margin();
    display: block;
    width: 15px;
    height: 20px;
  }

  // media
  @media (min-width: $screen-sm-min) {
    &__title {
      font-size: rem(21);
    }
    &__arrow {
      height: rem($widget-primary-arrow-size);
      width: rem($widget-primary-arrow-size);
    }
  }
  @media (min-width: $screen-md-min) {
  }
  @media (min-width: $screen-lg-min) {
  }

}