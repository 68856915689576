.headline {
  $item-top-gutter: 0.5em;
  $item-left-gutter: 2em;

  &__row {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    flex-wrap:       nowrap;

    margin-top:      - $item-top-gutter;
    margin-left:     - $item-left-gutter;
  }
  &__col {
    margin-top:  $item-top-gutter;
    margin-left: $item-left-gutter;
  }
}