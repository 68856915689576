.small-post {

  color: $base-font-color;

  &--inverse {
    color: $inverse-secondary-font-color;
  }

  &__image {
    display:       block;
    margin-bottom: 5px;
  }

  &__date {
    display:        block;
    padding-bottom: 3px;

    font-size:      rem(11);
    text-transform: uppercase;
    color:          $secondary-font-color;
  }

  &__title {
    font-weight: 700;
    font-size:   rem(14);
    color:       $strong-font-color;

    &--one-line {
      @include one-line-ellipsis();
    }
  }
  &--inverse &__title {
    color: $inverse-font-color;
  }

  &__desc {
    padding-top: 2px;

    font-size:   em(13);
    line-height: 1.2;
  }

  @media (min-width: $screen-sm-min) {

    display:      table;
    width:        100%;
    table-layout: fixed;

    &__image {
      display:        table-cell;
      vertical-align: top;
      width:          25%;
      padding-right:  10px;
      margin-bottom:  0;

      &--xl {
        width: 40%;
      }

      img {
        max-width: 100%;
      }
    }

    &__inner {
      display:        table-cell;
      vertical-align: top;
    }
  }
}