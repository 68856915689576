.active-filters{
	$gutter: 10px;

	@include clearfix;

	&__wrapper{
		margin: (-$gutter) 0 0 (-$gutter);
	}

	&__item {
		float: left;
		margin: $gutter 0 0 $gutter;
	}

	&__btn {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		text-decoration: none;
		color: $strong-font-color;
		padding: rem(8);
		border: 1px solid $base-border-color;

		transition-property: color, background;
		transition-duration: $theme-effects-time;

		&:hover {
			// background: $theme-secondary-bg;
			color: $theme-accent-color--lighten;
		}

		&-link {
			line-height: 1;		
		}

		&-ico {
			display: block;
			height: rem(10);
			width: rem(10);

			margin-left: rem(8px);

			svg {
				display: block;
			}
		}
	}

}