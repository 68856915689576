.brand-sidebar {
  display: block;
  text-decoration: none;

  background: $theme-secondary-bg;
  padding: em(10) em(10) em(20);

  &__row {}
  &__col {
  }
  &__desc {
  }
  &__title {
  }

  &__photo {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    //height: $bradns-image-height;
  }

  &__img {
    //@include absolute-center-translate;
    max-width: 100%;
    max-height: 100%;
    padding: 20px;
  }

}