.cart-summary {
  $items-hor-padding: 1.5em;
  $items-ver-padding: 1.5em;

  &__table {
    border:  1px solid $base-border-color;
    padding: $items-hor-padding $items-ver-padding;
  }
  &--borderless &__table {
    border: 0;
    padding: {
      top:   0;
      left:  0;
      right: 0;
    }
  }

  &__subtotal {
    border:     1px solid $base-border-color;
    border-top: 0;
    padding:    $items-hor-padding $items-ver-padding;
  }
  &--borderless &__subtotal {
    border:     0;
    padding: {
      bottom: 0;
      left:   0;
      right:  0;
    }
    border-top: 1px solid $base-border-color;
  }

  &__total {
    background: $theme-secondary-bg;
    padding:    $items-hor-padding $items-ver-padding;
  }
  &--borderless &__total {
    padding: {
      left:  0;
      right: 0;
    }
  }
}