.catalog-toolbar {
  $item-gutter: 8px;

  display: flex;
  flex-wrap: wrap;



  font-size: 0.9rem;

  margin-top: - em($item-gutter);
  margin-left: - em($item-gutter);

  &__item {
    padding-top: em($item-gutter);
    padding-left: em($item-gutter);
  }

  &__label {
    display: table-cell;
    padding-right: 10px;
    vertical-align: middle;
    white-space: nowrap;
  }

  &__field {
    display: table-cell;
    vertical-align: middle;

    &--min-w-200 {
      min-width: em(200);
    }
  }

  &__control {
  }

  @media (min-width: $screen-sm-min) {
    flex-wrap: nowrap;
  }
  @media (min-width: $screen-md-min) {}
  @media (min-width: $screen-lg-min) {}
}