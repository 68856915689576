.cart-subtotal {

  font-size: 0.9em;

  &__row {
    padding:    0.6em 0;
    border-top: 1px dashed $base-border-color;

    display:    table;
    width:      100%;
    & > * {
      display: table-cell;
    }

    &:first-child {
      padding-top: 0;
      border-top:  0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &--borderless {
      border-top: 0;
    }
  }

  &__label {
    color: $strong-font-color;
  }
  &__value {
    text-align: right;
  }
}