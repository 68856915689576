.cart-product {

  font-size: 0.9em;
  display:   flex;
  //color:     $strong-font-color;

  &__photo {
    width:       33%;
    flex-shrink: 0;
    max-width:   70px;
  }

  &__info {
    margin-left: 0.7em;
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 0.2em;
    }
  }

  &__title {
    color:         $strong-font-color;
    margin-bottom: 0.5em;
    font-size:     1.1em;
  }

  &__label {
    display:     inline-block;
    color:       $strong-font-color;
    font-weight: 700;
  }
  &__value {
    display: inline-block;
  }

  &__price {
    font-size: 1.1em;
  }

}