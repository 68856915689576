.properties {
  &__list {
    list-style:  outside disc;
    color:       $strong-font-color;
    margin-left: 25px;
  }
  &__item {
  }
  &__key {
    display:      inline-block;
    font-weight:  700;
    margin-right: 0.5em;

    &:after {
      content: ":";
    }
  }
  &__value {
    display: inline-block;
  }

  &__tooltip {
    display:        inline-block;
    vertical-align: text-bottom;
  }
}