/* Bootstrap rewrite */
.form-input {
  font-size: 1rem;

  &__group {
    @extend .input-group;

    &-item {
      @extend .input-group-btn;
    }

    &-btn {
      @extend .btn, .btn-default;
    }
  }

  &__control {
    @extend .form-control;
    height: 100%;

    &--quantity {
      width: em($product-quantity-width);
      padding-left: 0.2em !important;
      padding-right: 0.2em !important;
      text-align: center;


      // reset input[number] browser styles
      -moz-appearance: textfield;
      -webkit-appearance: textfield;

      &::-webkit-inner-spin-button {
        display: none !important;
      }
    }
  }
  &--lg &__control {
    @extend .input-lg;

    &--quantity {
      width: em($product-quantity-width--lg);
    }
  }

  &--product-base {
    .form-input__group {
      @extend .input-group-lg;
    }
  }

  &__info {
    margin-top: 0.5em;
  }

}

.form-control {
  box-shadow: none;

  &:disabled,
  &--disabled {
    background: $theme-secondary-bg;
    cursor: not-allowed;
  }

  &:focus {
    border-color: $focus-border-color;
    box-shadow: 1px 1px $focus-border-color, 0 0 8px rgba($theme-accent-color, 0.6);
  }
}