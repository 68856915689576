.widget-secondary {

  &__inner {
    position: relative;
    padding:  0 $widget-primary-arrow-size*1.5;
  }
  &__header {
    position:       relative;
    margin-bottom:  rem(15px);
    padding-bottom: 1em;
    border-bottom:  1px solid $base-border-color;
  }
  &__title {
    display:        inline-block;
    vertical-align: baseline;
    line-height:    1;
    color:          $widget-secondary-title-color;
    font-size:      rem(21);
    //font-weight: 700;
  }

  &__arrow {
    position:   absolute;
    top:        0;
    bottom:     0;

    display:    block;
    cursor:     pointer;
    margin:     auto;
    height:     rem($widget-secondary-arrow-size);
    width:      rem($widget-secondary-arrow-size);

    border:     1px solid $base-border-color;
    background: $widget-secondary-arrow-background;
    color:      $widget-secondary-arrow-color;

    transition: all $theme-effects-time;

    &--prev {
      left:  0;
      right: auto;
    }
    &--next {
      right: 0;
      left:  auto;
    }

    /* styles for disabled arrow, when slick carousel infinite is disabled */
    &.slick-disabled,
    &.slick-arrow.slick-hidden {
      display: block;
      opacity: 0.2;
      cursor: default;
    }

    &:not(.slick-disabled):not(.slick-hidden):hover {
      color:        $widget-secondary-arrow-color-hover;
      background:   $widget-secondary-arrow-background-hover;
      border-color: $widget-secondary-arrow-background-hover;
    }

    &:not(.slick-disabled):not(.slick-hidden):active {
      transform: scale(1.1);
    }
  }

  &__icon {
    @include absolute-center-margin();
    display: block;
    width:   15px;
    height:  20px;
  }

}