.user-panel {
  $item-top-gutter: 0.5em;
  $item-left-gutter: 1em;

  display:         flex;
  justify-content: space-between;
  align-items:     center;

  margin-left:     - $item-left-gutter;
  margin-top:      - $item-top-gutter;

  &__item {
    position:    relative;
    display:     flex;
    align-items: center;
    margin-left: $item-left-gutter;
    margin-top:  $item-top-gutter;

    &--empty {
      color: $inverse-secondary-font-color;
    }

    &--separated {
      border-left:  1px solid $inverse-secondary-font-color;
      padding-left: $item-left-gutter;
    }
  }

  &__icon {
    display: block;
    height:  1.1em;
    width:   1.1em;
    color:   $theme-accent-color;

    &--profile {
      color: $inverse-font-color;
    }
  }
  &__item--empty &__icon {
    color: inherit;
  }

  &__link {
    & + & {
      &::before {
        content:       "/";
        display:       inline-block;
        color:         $inverse-font-color;
        padding-left:  0.2em;
        padding-right: 0.2em;
      }
      //margin-left:  0.5em;
      //border-left:  1px solid currentColor;
      //padding-left: 0.5em;
    }
  }
  &__item--empty &__link {
    cursor:         default;
    pointer-events: none;
    color: inherit;
  }
  &__icon + &__link {
    margin-left: 0.3em;
  }

  &__drop {
    position:   absolute;
    top:        100%;
    right:      0;
    z-index:    1;

    visibility: hidden;
    opacity:    0;

    transition: all $theme-effects-time;
  }
  &__item:hover > &__drop {
    visibility: visible;
    opacity:    1;
  }
}