.tree-nav {
  position: relative;

  border: 1px solid $tree-nav-border-color;
  text-align: left;
  background: $tree-nav-bg-color;
  box-shadow: 0 3px 4px $tree-nav-shadow-color;

  &__item {
    position: relative;

    &--top-drop {
      position: static;
    }
  }

  &__link {
    display: block;
    white-space: nowrap;
    position: relative;
    padding: 10px 60px 10px 10px;
    color: $tree-nav-link-color;
    text-decoration: none;

    transition: color $theme-effects-time;
  }
  &__item.is-active > &__link {
    color: lighten($tree-nav-link-hover-color, 10%);
  }
  &__item:hover > &__link {
    color: $tree-nav-link-hover-color;
  }

  &__arrow {
    position: absolute;
    color: $base-border-color;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: rem(8px);
    height: rem(8px);
  }

  &__drop {
    position: absolute;
    top: 0;
    left: 100%;

    visibility: hidden;
    opacity: 0;
    max-height: 1px;
    overflow: hidden;
    transition-property: visibility, opacity, max-height;
    transition-duration: $theme-effects-time;

    //transition-property: visibility, opacity, height, max-height;
    //transition-duration: $theme-effects-time;

    //Element Modifiers
    &[data-nav-direction="rtl"] {
      right: 100%;
      left: auto;
    }
  }
  &__item:hover > &__drop {
    visibility: visible;
    opacity: 1;
    max-height: 3000px;
    overflow: visible;
  }

}