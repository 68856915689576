.paginator {

  $paginator-size: rem(35);
  $arrow-size: rem(15);

  height: $paginator-size;

  @include valign-before;

  &__item {
    max-width:   rem(40);
    margin:      0 rem(10);

    font-weight: bold;
    text-align:  center;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      @include link-second;
      display: inline-block;
    }

    &--active {
      color: $theme-accent-color;
    }

    &--first {
    }

    &--last {
    }
    &--dots {
    }

    &--next,
    &--prev {
      font-size: 0;
    }

  }

  &__arrow {
    display: block;

    height:  $arrow-size;
    width:   $arrow-size;

  }

}