.cart-prod-table {


  $first-cell-width: 25px;

  $delete-size: 1em !default;
  $plus-size: 20px;

  $info-items-top-gutter: 1em;
  $info-items-left-gutter: 1em;

  $purchase-items-top-gutter: 0.5em;
  $purchase-items-left-gutter: 0.5em;

  &__row {
    display:     flex;
    align-items: flex-start;

    &:not(:last-child) {
      padding-bottom: 1em;
      margin-bottom:  1em;
      border-bottom:  1px dashed $base-border-color;
    }
  }

  &__delete {
    flex-shrink: 0;
  }
  &__info {
    flex-grow:       1;

    display:         flex;
    justify-content: space-between;
    align-items:     flex-start;
    flex-wrap:       wrap;

    padding-left:    0.5em;
    margin-top:      - $info-items-top-gutter;
    margin-left:     - $info-items-left-gutter;

    & > * {
      padding-top:  $info-items-top-gutter;
      padding-left: $info-items-left-gutter;
    }
  }

  &__products-wrapper {
    flex-basis: 200px;
    flex-grow:  1;
  }
  &__purchase {
    flex-shrink: 0;
    flex-basis: em(130px);

    display:     flex;
    justify-content: space-between;
    align-items: center;

    margin-top:  - $purchase-items-top-gutter;
    margin-left: - $purchase-items-left-gutter;

    & > * {
      padding-top:  $purchase-items-top-gutter;
      padding-left: $purchase-items-left-gutter;
    }
  }

  &__quantity {
    flex-shrink: 0;
    flex-basis: 4em;
    text-align: center;
  }
  &__price {
  }

  &__product {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    &--icon {
      width:       em($plus-size);
      height:      em($plus-size);
      margin-left: em(100);

      color:       $secondary-font-color;
      &:last-child {
        display: none;
      }
    }
  }

  &__icon {
    @include absolute-center-margin();
    display: block;
    width:   70%;
    height:  70%;

    &--lg {
      width:  100%;
      height: 100%;
    }
  }

  @media (min-width: $screen-sm-min) {
  }
  @media (min-width: $screen-md-min) {
  }
  @media (min-width: $screen-lg-min) {
  }


}