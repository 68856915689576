.product-intro {

  // layouts
  &__row {
    margin-bottom: 1.7em;

    &:empty {
      display: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &--flex {
      display:         flex;
      flex-flow:       row wrap;
      justify-content: space-between;
      align-items:     center;
    }
    &--dashed {
      border:       1px dashed $base-border-color;
      padding:      em(8) 0;
      border-left:  0;
      border-right: 0;
    }
    &--double {
      margin-bottom: 3em;
    }
    &--decorate + &--decorate {
      padding-top: 1.7em;
      border-top: 1px dashed $base-border-color;
    }
  }
  &__col {
    padding: 0.25em 0;

    &--flex {
      display:     flex;
      align-items: center;
    }
  }

  // elements
  &__label {
  }
  &__value {
  }
  &__label + &__value {
    margin-left: 0.5em;
  }

  &__archived {
    font-size:  1.4em;

    display:    inline-block;
    padding:    em(10) em(30);
    color:      $strong-font-color;
    background: $theme-secondary-color;
  }

  &__bonus{
    margin-top: 10px;
    display: inline-block;
  }

}