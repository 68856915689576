.brand-image {
  display: block;
  @include product-plate-styles();
  border: 1px solid $base-border-color;
  text-decoration: none;


  &__photo {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 50%;
  }

  &__img {
    @include absolute-center-translate;
    display: block;
    max-height: 100%;
    max-width: 100%;
    padding: 10px;
  }

  &__title {
    display: block;
    padding: em(5) em(10) em(10);
    text-align: center;
    font-weight: bold;
    color: $strong-font-color;
  }
}