/*
--------------------------------------------------
Navigation table-navs styles
--------------------------------------------------
*/
.table-nav {
  $z-intdex: $z-index-s;

  position:      relative;
  border-bottom: 1px solid $base-border-color;

  //Elements
  &__list {
    display: table;
    height:  $table-nav-height;
    margin:  0 auto;
  }

  &__item {
    position:         relative;
    display:          table-cell;
    height:           100%;
    text-align:       center;
    vertical-align:   middle;
    background-color: $table-nav-bg-color;
    border-left:      1px solid transparent;

    transition:       border-color $theme-effects-time;

    &:hover {
      border-color: $table-nav-bg-hover-color;
    }

    &:last-child {
      border-right: 1px solid transparent;
    }

    //Element Modifiers
    &.is-active {
      border-color: $base-border-color;

      &:last-child {
        border-color: $base-border-color;
      }

      &::after {
        content:    "";
        position:   absolute;
        left:       0;
        bottom:     -1px;

        display:    block;
        width:      100%;
        height:     1px;
        background: $theme-main-bg;
      }
    }
    &.is-active + & {
      border-left-color: $base-border-color;
    }
  }

  &__link {
    font-size:           0;
    display:             block;
    height:              100%;
    color:               $table-nav-link-color;
    font-weight:         700;
    text-transform:      uppercase;
    text-decoration:     none;

    transition-property: background, color;
    transition-duration: $theme-effects-time;

    &:before {
      content:        '';
      height:         100%;
      padding:        10px 0;
      display:        inline-block;
      vertical-align: middle;
    }

    &-helper {
      display:        inline-block;
      vertical-align: middle;
      font-size:      rem(13);
      padding:        5px 30px;
    }
  }
  &__item:hover &__link {
    background: $table-nav-bg-hover-color;
    color:      $table-nav-link-hover-color;
  }
  &__item--active &__link {
    color: $theme-accent-color;
  }

  &__drop {
    position:   absolute;
    z-index:    1000;
    top:        100%;
    left:       0;

    visibility: hidden;
    opacity:    0;
    max-height: 1px;
    overflow:   hidden;
    transition-property: visibility, opacity, max-height;
    transition-duration: $theme-effects-time;

    //Element Modifiers
    &[data-nav-direction="rtl"] {
      right: 0;
      left:  auto;
    }
  }

  &__item:hover > &__drop {
    visibility: visible;
    opacity:    1;
    max-height: 3000px;
    overflow:   visible;
  }

}