.product-fullinfo {

  &__header {
    border-bottom:  1px solid $base-border-color;
    padding-bottom: 0.25em;
    margin-bottom:  2em;
  }
  &__title {
    color:     $strong-font-color;
    font-size: 1.75em;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: em(60);
    }
  }

}