.delivery-radio {

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (min-width: $screen-sm-min) {
    flex-wrap: nowrap;
  }

  &__info {
    flex-grow: 1;
  }
  &__add-info {

  }

  &__title {
    color: $strong-font-color;
    font-weight: 700;
  }
  &__price {
    display: block;
    text-align: right;
    color: $strong-font-color;
    font-weight: 700;

    &--accent {
      color: $attention-color;
      font-weight: 700;
    }
  }

  &__tooltip {
    display: inline-block;
    vertical-align: middle;
  }
  &__add-info {
    margin-left: 1em;
    font-weight: 400;
    display: block;
    color: $base-font-color;
  }

  &__spoiler {
    flex-grow: 1;

    & + & {
      margin-top: 0.5em;
    }
  }

  &__spoiler-row {
    display: flex;
  }
  &__spoiler-col {
    padding-top: 10px;
    max-width: calc(100% - 85px);

    @media (min-width: $screen-sm-min) {
      max-width: 250px;
    }

    &:not(:first-child) {
      padding-left: 15px;
      flex-grow: 1;
    }

    &--label {
      flex-shrink: 0;
      flex-grow: 0;
      width: 70px;
      padding-top: 15px;
    }

    .select2 {
      width: 100% !important;
    }
  }
}