/*
--------------------------------------------------
Navigation menus styles
--------------------------------------------------
*/
.list-nav {
  $list-nav-hor-offset: 20px;
  $list-nav-ver-offset: 5px;

  //Modifiers
  &--align {

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }

  }

  &--valign {

    &-middle {
      .list-nav__item {
        vertical-align: middle;
      }
    }

    &-bottom {
      .list-nav__item {
        vertical-align: bottom;
      }
    }

  }

  //Elements
  &__items {
    font-size:   0;
    margin-top:  -$list-nav-ver-offset;
    margin-left: -$list-nav-hor-offset;
  }

  &__item {
    display:        inline-block;
    font-size:      0.9rem;
    vertical-align: top;

    position:       relative;

    margin-left:    $list-nav-hor-offset;
    margin-top:     $list-nav-ver-offset;

    &--active {
      color: $inverse-secondary-font-color;
    }
  }
  //&__item--active &__link {
  //  color: $inverse-secondary-font-color;
  //}

  &__drop {
    position:   absolute;

    z-index:    1000;
    top:        100%;
    left:       0;

    visibility: hidden;
    opacity:    0;
    transition: all $theme-effects-time;

    //Element Modifiers
    &--rtl {
      right: 0;
      left:  auto;
    }
  }
  &__item:hover &__drop {
    visibility: visible;
    opacity:    1;
  }

}