.frame-content {
  font-size: 1rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1em;

    &--on-dark {
      margin-bottom: 0;
      padding: 1.5em;
      background: $theme-secondary-bg;
    }
  }
  &__footer {
  }
  &__body {

    border: 1px solid $base-border-color;
    padding: 1em;

    & + & {
      border-top: 0;
    }

    &--borderless {
      padding: 0;
      border: 0;
    }
  }
  &__row {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__title {
    font-size: 1.1em;
    font-weight: 700;
    color: $theme-accent-color;

    &--xl {
      font-size: 1.5em;
      color: $strong-font-color;
    }
  }
  &__control {

  }
  &__item {
    & + & {
      margin-left: 0.5em;
    }
  }

}