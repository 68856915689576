//Bootstrap grid cols vertical gutter
@mixin grid-vertical-gutter($gutter) {

  margin-bottom: -$gutter;

  & > [class*="col-"],
  .slick-track > [class*="col-"]{
    padding-bottom: $gutter;
  }

}


// vertical and horizontal alignment
@mixin absolute-center-translate {
  position:  absolute;
  top:       50%;
  left:      50%;
  transform: translate(-50%, -50%);
}


// absolute vertical and horizontal align. elemet's height should be set
@mixin absolute-center-margin {
  position: absolute;
  top:      0;
  right:    0;
  bottom:   0;
  left:     0;
  margin:   auto;
}


// vertical align items in block via before
@mixin valign-before {

  &:before {
    display:        inline-block;
    content:        "";
    height:         100%;

    vertical-align: middle;
  }

  & > * {
    display:        inline-block;
    vertical-align: middle;
  }
}


@mixin flex-equal-grid($media-settings, $bottom-gutter, $va: stretch, $container: list, $item: item) {

  &__#{$container} {
    display:         flex;
    justify-content: space-between;
    align-items:     #{$va};
    flex-wrap:       wrap;

    margin-bottom:   - $bottom-gutter;

    &::after {
      content: "";
      flex:    auto;
    }
  }

  &__#{$item} {
    width:         100%;
    margin-bottom: $bottom-gutter;
  }

  @each $breakpoint, $settings in $media-settings {
    @media (min-width: #{$breakpoint * 1px}) {
      &__#{$container} {
        margin-right: - nth($settings, 2);
        margin-left:  - nth($settings, 2);
      }

      &__#{$item} {
        width:        calc(#{percentage(1/ nth($settings, 1))} - #{nth($settings, 2) * 2});
        margin-right: nth($settings, 2);
        margin-left:  nth($settings, 2);
      }
    }
  }
}


@mixin product-plate-styles() {
  border:              1px solid transparent;

  transition-property: box-shadow, transform, border-color;
  transition-duration: $theme-effects-time;

  &:hover {
    border:     1px solid $base-border-color;
    box-shadow: 0 5px 10px rgba(#000, 0.1);
  }
}


@mixin one-line-ellipsis() {
  overflow:      hidden;
  white-space:   nowrap;
  text-overflow: ellipsis;
}


@mixin ghost-triangle-with-border($width, $bg, $brd-color, $brd-width, $dir, $position-origin: center, $position-offset: 0) {
  $transform: none;
  $offset-after: true;

  @if $position-origin == center {
    @if $dir == top or $dir == bottom {
      $position-origin: left;
      $transform: translateX(-50%);
    } @else {
      $position-origin: top;
      $transform: translateY(-50%);
    }
    $position-offset: 50%;
    $offset-after: false;
  }

  @if $dir == top {
    $dir: bottom;
  } @else if $dir == right {
    $dir: left;
  } @else if $dir == bottom {
    $dir: top;
  } @else {
    $dir: right;
  }

  position: relative;

  &::before,
  &::after {
    content:             "";
    position:            absolute;

    #{$dir}:             100%;
    #{$position-origin}: $position-offset;
    transform:           $transform;

    display:             block;
    width:               0;
    height:              0;
  }
  &::before {
    border: {
      width: $width + $brd-width;
      style: solid;
      color: transparent;
      #{$dir}: {
        width: $width + $brd-width;
        color: $brd-color;
      }
    }
  }
  &::after {
    @if $offset-after {
      margin: {
        #{$position-origin}: $brd-width;
      }
    }
    border: {
      width: $width;
      style: solid;
      color: transparent;
      #{$dir}: {
        width: $width;
        color: $bg;
      }
    }
  }
}