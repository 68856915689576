.compare {

  &__product {
    position: relative;
    overflow: hidden;
    padding: 0 10px;
  }

  &__params {
    padding-top: 20px;
    font-size: 0.9rem;
    color: #000;

    &-item {
      padding: 2px 10px;
      height: 40px;
      overflow: hidden;

      &:nth-child(odd) {
        background: $compare-product-bg;
        border: 1px dashed darken($compare-product-bg, 5%);
        border-right: 0;
        border-left: 0;
      }

      &--title {
        font-weight: bold;
      }
    }

    &-value {
      display: table;
      width: 100%;
      height: 100%;

      &:before {
        content: "";
        width: 0;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
      }
    }

    &-text {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
    }
  }

  &__delete {
    position: absolute;

    top: 10px;
    right: 10px;
  }
}