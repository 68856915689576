.order-details {

  &__group {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  &__row {
    display: table-row;
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 10px;

    &:not(:first-child) {
      padding-left: 30px;
    }

    &--title {
      width: 30%;
      font-weight: bold;
    }
  }
  &__row:last-child &__cell {
    padding-bottom: 0;
  }
}