.sizes-table {

  overflow-x: auto;

  table {
    width: 100%;
    min-width: 550px;
  }

  td {
    padding: 0.5em 1em;

    &:not(:first-child) {
      text-align: right;
    }
    &:first-child {
      font-weight: 700;
    }
  }
  tr {
    &:not(:last-child) {
      border-bottom: 1px solid $base-border-color;
    }

    &:first-child {
      font-weight: 700;
      border-bottom: 2px solid $base-border-color;
    }
  }
}