.widget-primary {

  $arrow-size: 42px;

  .slick-track {
    padding-bottom: 10px;
  }

  &__inner {
    position: relative;
    //border: 1px solid red;
  }

  &__header {
    margin-bottom: rem(25px);

    text-align: center;

    &::after {
      content: "";

      display: block;
      width: em(70px);
      height: 1px;
      margin: rem(15) auto 0;

      background: $widget-primary-decorator-color;
    }
  }

  &__title {
    @include one-line-ellipsis();
    font-size: rem(16);
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1;

    color: $widget-primary-title-color;
  }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;

    display: block;
    cursor: pointer;
    margin: auto;
    height: rem($widget-primary-arrow-size);
    width: rem($widget-primary-arrow-size);

    border: 1px solid $base-border-color;
    background: $widget-primary-arrow-background;
    color: $widget-primary-arrow-color;

    transition: all $theme-effects-time;

    &--prev {
      left: -10px;
      right: auto;
    }
    &--next {
      right: -10px;
      left: auto;
    }

    /* styles for disabled arrow, when slick carousel infinite is disabled */
    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
      cursor: default;
    }

    &:not(.slick-disabled):hover {
      color: $widget-primary-arrow-color-hover;
      background: $widget-primary-arrow-background-hover;
      border-color: $widget-primary-arrow-background-hover;
    }

    &:active {
      transform: scale(1.1);
      //animation: jumpUp 300ms ease-out 1;
    }
  }

  &__icon {
    @include absolute-center-margin();
    display: block;
    width: 15px;
    height: 20px;
  }

  // media
  @media (min-width: $screen-sm-min) {
    &__title {
      font-size: rem(21);
    }
    &__arrow {

    }
  }
  @media (min-width: $screen-md-min) {
  }
  @media (min-width: $screen-lg-min) {
  }
}