.modal {
  position:   relative;
  margin:     0 auto;
  width:      90%;
  max-width:  $modal-size;
  background: $modal-background;

  /* Modal modifiers */
  &--sm {
    max-width: $modal-size-sm;
  }
  &--lg {
    max-width: $modal-size-lg;
  }


  /* layouts */
  &__header {
    display:         flex;
    justify-content: space-between;
    align-items:     center;

    padding:         $modal-top-padding $modal-hor-padding $modal-internal-gutter;
  }
  &__content {
    padding: $modal-internal-gutter $modal-hor-padding;

    &:last-child {
      padding-bottom: $modal-internal-gutter * 2;
    }
  }
  &__footer {
    padding: $modal-internal-gutter $modal-hor-padding $modal-bottom-padding;
    &--bordered {
      border-top: 1px dashed $modal-border-color;
    }
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
    &--flex {
      display:         flex;
      justify-content: space-between;
      align-items:     center;
    }

  }

  /* elements */
  &__title {
    font-size:      em(18);
    line-height:    1;
    font-weight:    700;
    text-transform: uppercase;
    color:          $modal-header-color;
    padding-right:  20px;
  }

  &__close-button {
    position:   relative;
    display:    block;
    width:      em(25);
    height:     em(25);
    border:     1px solid $modal-border-color;
    color:      $strong-font-color;

    transition: all $theme-effects-time;

    &:hover {
      background:   $theme-main-color;
      border-color: $theme-main-color;
      color:        $inverse-font-color;
    }
    &:active {
      transform: scale(1.1);
    }
  }

  &__icon {
    @include absolute-center-margin();
    display: block;
    height:  50%;
    width:   50%;
  }
}