.header {
  display:         flex;
  justify-content: space-between;
  align-items:     center;

  &__icon {
    width:   25%;
  }
  &__logo {
    width:   50%;
    padding: 0 rem(10);
  }
  &__toolbar {
    width: 25%;
  }
  &__site-info {
    display: none;
    width:   50%;
  }

  @media (min-width: $screen-sm-min) {
  }
  @media (min-width: $screen-md-min) {

    &__icon {
      display: none;
    }
    &__site-info {
      display: block;
    }
    &__site-info,
    &__logo,
    &__toolbar {
      width: percentage(1/3);
    }
  }
  @media (min-width: $screen-lg-min) {
  }
}