.discount-drop-down {

  &__title {
  }
  &__link {
    text-decoration: underline;
  }
  &__body {
    margin-top: 1em;

    &.is-hidden {
      display: none;
    }

  }
}