//Countries flags icons
.ico-flag {
  display: inline-block;

  &--ru {
    @include sprite($-russia);
  }

  &--en {
    @include sprite($-united-states);
  }

  &--ua {
    @include sprite($-ukraine);
  }
}

/* icons in sprite*/
.ico {
  background: url("../_img/sprite.png") no-repeat;

  @each $sprite in $spritesheet-sprites {
    $sprite-name: nth($sprite, 10);
    &__#{$sprite-name} {
      @include sprite-position($sprite);
      @include sprite-width($sprite);
      @include sprite-height($sprite);
    }
  }
}

//Toggle mobile frame icons
.ico-mobile {
  color: black;
  width: rem(25px);
  height: rem(25px);
}