.news-widget {

  &__header {
    margin-bottom: em(20);
  }
  &__title {
    font-size:   em(21);
    font-weight: 700;
    color:       $strong-font-color;
  }
  &__inner {

  }
  &__item {
    &:not(:last-child) {
      margin-bottom: em(40);
    }
  }
}