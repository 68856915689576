.btn {
  @include valign-before();

  position:        relative;
  text-decoration: none;

  transition:      background $theme-effects-time, color $theme-effects-time;

  display:         inline-block;

  &:not(&--narrow) {
    padding: {
      left: em(25);
      right: em(25);
    }
  }

  & > * {
    &:not(:first-child) {
      margin-left: em(4px);
    }
  }

  &:focus,
  &:active {
    outline-color: $focus-border-color !important;
    border-color:  $focus-border-color;
    box-shadow:    1px 1px $focus-border-color, 0 0 8px rgba($theme-accent-color, 0.6);
  }

  &--disabled {
    cursor:  not-allowed;
    opacity: 0.5;
  }
  &--no-gutter {
    margin: 0 !important;
  }

  &__overlay {
    position:   absolute;
    z-index:    1;
    top:        0;
    right:      0;
    bottom:     0;
    left:       0;
    margin:     auto !important;
    background: rgba(#000, 0.75);
  }

  // icon height based on bootstrap $line-height-base. If you want to change icon size change only width
  &__icon {
    display:  inline-block;
    width:    1em;
    height:   em($base-font-size * $line-height-base);

    position: relative;

    &--angle {
      width:  em(8px);
    }

    &--bigger {
      width:  rem(18px);
      height: rem(18px);
    }

    &--search {
      width:  rem(17px);
      height: rem(20px);
    }

    &--spinner {
      color:  #FFF;
      height: 24px;
      width:  30px;
    }

    &--vertical-flip {
      transition: all $theme-effects-time;
    }
  }
  &__overlay &__icon {
    @include absolute-center-margin();
  }
  &--active &__icon--vertical-flip {
    transform: rotate(-180deg);
  }

  &__label {
    &--no-gutter {
      margin-left: 0 !important;
    }
  }
}

.btn-empty {
  border: 2px solid $base-border-color;
  background: $theme-secondary-bg;
  color: $strong-font-color;
  font-weight: 700;


  &:hover {
    color: $strong-font-color;
    background: $base-border-color;
  }
  &--transparent {
    background: rgba(#000, 0.6);
    color: $inverse-font-color;
    &:hover {
      background: rgba(#000, 0.2);
      color: $strong-font-color;
    }
  }
}

.btn-cart,
.btn-cart-p {
  color: $inverse-font-color;

  & input {
    color: $inverse-font-color;
  }
}

.btn-default {
  &:not(.btn--disabled):hover {
    background: lighten($btn-default-bg, 35%);
  }
}