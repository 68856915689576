.cart-total {
  display:   table;
  width:     100%;
  font-size: em(21);

  &__label {
    display: table-cell;
    color:   $strong-font-color;
  }
  &__value {
    display:    table-cell;
    text-align: right;
  }
}