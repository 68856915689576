.blog-widget {
  $hor-gutter: 16px;
  $ver-gutter: 8px;

  border: 1px solid $base-border-color;

  &--borderless {
    border: 0;
  }

  &__header {
    padding: em($ver-gutter) rem($hor-gutter);
    border-bottom: 1px solid $base-border-color;
  }
  &--borderless &__header {
    border: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  &__title {
    font-size: rem(18px);
    font-weight: 700;
    color: $strong-font-color;
  }
  &__body {
    padding: em($ver-gutter) em($hor-gutter);

    &--gutterless {
      padding: 0;
    }
  }
  &--borderless &__body {
    padding-left: 0;
    padding-right: 0;
  }

  &__list {
    padding: em($ver-gutter) 0;
  }
  &__item {
    display: block;
    color: $strong-font-color;

    &:not(:last-child) {
      margin-bottom: em($ver-gutter);
    }
  }
  &__author {
    text-transform: uppercase;
    color: $secondary-font-color;
  }
}