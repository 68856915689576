.banners {
  $row-top-gutter: em(10);
  $item-top-gutter: em(10);
  $item-left-gutter: em(10);

  &__row {

    margin-top:      - $item-top-gutter;
    margin-left:     - $item-left-gutter;

    &:not(:last-child) {
      padding-bottom: $row-top-gutter;
    }
  }

  &__item {
    position:    relative;
    min-width:   0;
    min-height:  0;

    margin-top:  $item-top-gutter;
    margin-left: $item-left-gutter;
  }

  &__main {
    width: 100%;
  }
  &__small {
    width: 50%;
  }

  @media (min-width: $screen-sm-min) {
    &__row {
      display:         flex;
      justify-content: space-between;
      flex-wrap:       wrap;
    }
    &__item {
      width: calc(50% - #{$item-left-gutter});

      &--wide {
        width: 100%;
      }
    }
  }
}