.tags-cloud {
  $item-top-gutter: 0.5em;
  $item-left-gutter: 0.75em;

  &__list {
    display: flex;
    flex-wrap: wrap;

    margin-top: - $item-top-gutter;
    margin-left: - $item-left-gutter;
  }
  &__item {
    padding-top: $item-top-gutter;
    padding-left: $item-left-gutter;
  }
  &__link {
    display: block;
    padding: 0.3em 0.5em;
    background: $theme-secondary-bg;

    transition: all $theme-effects-time;

    &:hover {
      box-shadow: 2px 2px 5px $base-box-shadow-color;
    }
    &:active {
      box-shadow: -1px -1px 2px $base-box-shadow-color;
    }
  }
}