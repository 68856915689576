.checkgroup {

  &__checkbox {
    display: none;
  }

  &__label {
    display: flex;
    align-items: center;

    input:disabled + & {
      cursor: not-allowed;
    }

    input + &::before {
      content: "";
      width: 1em;
      min-width: 1em;
      height: 1em;

      display: block;
      background: $theme-main-bg;


      transition: all $theme-effects-time;
      border: 1px solid $base-border-color;
    }
    input:not(:disabled) + &:hover::before {
      border-color: $theme-accent-color;
    }
    input:checked + &::before {
      border-color: $theme-accent-color;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path fill="#fff" d="M6.889 56.33l.023.035.017.025 22.46 33.69.001-.001a11.237 11.237 0 0 0 9.359 5.011c3.904 0 7.342-1.99 9.359-5.01l.001.001 44.954-67.432.03-.044.016-.024-.001-.001A11.189 11.189 0 0 0 95 16.34c0-6.213-5.037-11.25-11.25-11.25a11.237 11.237 0 0 0-9.359 5.011l-.001-.001-35.64 53.459L25.611 43.85h-.001a11.237 11.237 0 0 0-9.359-5.011C10.037 38.84 5 43.877 5 50.09c0 2.309.698 4.454 1.891 6.239l-.002.001z"/></svg>') no-repeat 50% 50%, $theme-accent-color;
      background-size: em(8) em(8);
    }
  }

  &__link {
    @include link-second();
    flex-shrink: 1;
    margin-left: em(5);

    &.disabled {
      cursor: not-allowed;
    }
  }
  &__label:hover &__link:not(.disabled) {
    color: $theme-accent-color;
  }
  input:checked + &__label > &__link {
    color: $theme-accent-color;
  }

  &__count {
    margin-left: em(5px);
    color: $secondary-font-color;
  }
}