.page {

  /* Mobile sidebar slide panel */
  &__mobile {
    position:                   fixed;
    width:                      $page-mobile-width;
    height:                     100%;
    top:                        0;
    left:                       -$page-mobile-width;
    z-index:                    $page-ovarlay-z-index + 1;
    background:                 $page-mobile-bg;
    color:                      $page-mobile-color;
    overflow-y:                 auto;
    overflow-x:                 hidden;
    transition:                 transform $page-mobile-speed;

    /* enables momentum scrolling in iOS overflow elements */
    -webkit-overflow-scrolling: touch;

    &--js-open {
      transform: translateX($page-mobile-width);
    }

  }

  /* Page overlay visible if mobile panes is open */
  &__overlay {
    display:          block;
    @include position(fixed, 0 0 0 0);
    z-index:          $page-ovarlay-z-index;
    background-color: $page-overlay-color;
    animation:        fade 500ms;

    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  /*
      * Main site content frame
      * Shifting to right when mobile menu is siliding.
      * Adding via javascript by clicking on mobile menu btn
  */
  &__body {
    @include transition(transform $page-mobile-speed);
    display:    flex;
    flex-flow:  column;
    min-height: 100vh;

    &--js-pushed {
      @include transform(translateX($page-mobile-width));
      /* Disable scrolling while mobile bar is opened */
      height:   100vh;
      overflow: hidden;
    }
  }

  &__mainframe {
    @extend .container;
  }

  /* Main container inside wrapper for sticky footer */
  &__wrapper {
    flex-grow: 1;
  }

  /* Container for static width frames */
  &__container {
    //extending bootstrap container
    @extend .container;
  }

  /* Header line and main header wrapper */
  &__hgroup {
  }

  /* Header line on top */
  &__headline {
    font-size:  em(13);
    padding:    em(12) 0;
    color:      $inverse-font-color;
    background: $theme-main-color;
  }

  /* Main header */
  &__header {
    padding:       15px 0;
    border-bottom: 1px solid $base-border-color;
  }

  /* Page content wrapper */
  &__content {
    padding-top:    $page-content-top-gutter;
    padding-bottom: 90px;
  }

  /* Main horisontal navigation wrapper */
  &__mainnav-hor {
    //padding-bottom: 20px;
  }

  /* Breadcrumbs wrapper */
  &__breadcrumbs {
    padding-top: 20px;
  }

  /* Site benefits wrapper */
  &__benefits {
    background: $benefits-bg-color;
    border-top: 1px solid $benefits-border-color;
    padding:    20px 0;
  }

  /* Page viewed wrapper */
  &__viewed {
    margin-top: 70px;
  }

  /* Content Sticky footer */
  &__fgroup {
    position:   relative;
    background: $page-fgroup-bg;
  }

  /* Main footer wrapper */
  &__footer {
    padding: 30px 0 0;
  }

  @media (min-width: $screen-sm-min) {
  }
  @media (min-width: $screen-md-min) {
    &__header {
      padding: 20px 0;
    }
  }
  @media (min-width: $screen-lg-min) {
  }
}