.soc-groups {
  $icon-size: 75%;

  margin-left: - rem($soc-group-gutter);
  margin-bottom: - rem($soc-group-gutter);
  @include clearfix;

  &__ico {
    position: relative;

    float: left;
    margin-left: rem($soc-group-gutter);
    margin-bottom: rem($soc-group-gutter);
    width: rem($soc-group-icon-size);
    height: rem($soc-group-icon-size);

    border-radius: $soc-group-border-radius;
    border: 1px solid $soc-group-border-color;
    color: $soc-group-icon-color;
    background: $soc-group-bg;

    transition: all $theme-effects-time;

    &:hover {
      background: $soc-group-bg-hover;
    }
    &:active {
      animation: jumpUp 300ms linear;
    }
    
    svg {
      @include absolute-center-margin();

      width: $icon-size;
      height: $icon-size;
    }
  }
}