.product-buy {

  $item-left-gutter: 12px;
  $item-top-gutter: 8px;

  &__flex-container {
    display:         flex;
    justify-content: flex-start;
    align-items:     center;
    flex-flow:       wrap;

    margin-left:     - em($item-left-gutter);
    margin-top:      - em($item-top-gutter);
  }

  &__item {
    margin-left: em($item-left-gutter);
    margin-top:  em($item-top-gutter);
  }
}