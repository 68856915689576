.product-photo {

  max-width: $product-photo-max-fluid-width;
  margin:    0 auto;

  &__row {
    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  &__item {
    position:       relative;
    display:        block;
    width:          100%;
    padding-bottom: $product-photo-height-to-width-ratio;
    max-height:     100%;
    background:     $theme-main-bg;

    text-align:     center;
    border:         1px solid $product-photo-border-color;

    &--no-photo {
      @include link-empty;
    }

    &--fix-xs,
    &--fix-sm,
    &--fix-md,
    &--fix-lg {
      padding-bottom: 0;
    }

    &--fix-xs {
      width:      $product-photo-fix-height-xs;
      height:     $product-photo-fix-height-xs;
      max-height: $product-photo-fix-height-xs;

      .product-photo__img {
        padding: 5px;
      }
    }
    &--fix-sm {
      height:     $product-photo-fix-height-sm;
      max-height: $product-photo-fix-height-sm;
    }
    &--fix-md {
      height:     $product-photo-fix-height-md;
      max-height: $product-photo-fix-height-md;
    }
    &--fix-lg {
      padding-bottom: 0;
      height:         $product-photo-fix-height-lg;
      max-height:     $product-photo-fix-height-lg;
    }

    &[data-zoom-image] {
      cursor: zoom-in;
    }

  }

  &__img {
    /* use important to override slick slide img display:block rule */
    @include absolute-center-margin;
    display:    block;
    //padding:    10px;
    max-height: inherit;
  }

  &__labels {
    position: absolute;
    top:      0;
    left:     0;
  }

  &__label {
    $size: 3.5em;

    font-size:        em(10);

    display:          block;
    width:            $size;
    height:           $size;
    line-height:      $size;
    font-weight:      700;
    text-align:       center;
    text-transform:   uppercase;
    white-space:      nowrap;

    border-radius:    50%;
    background-color: $product-label-bg-default;
    color:            #FFF;

    &:not(:first-child) {
      margin-top: 1px;
    }

    &--hit {
      background-color: $product-label-bg-hit;
    }

    &--hot {
      background-color: $product-label-bg-hot;
    }

    &--action {
      background-color: $product-label-bg-action;
    }

    &--discount {
      background-color: $product-label-bg-discount;
    }
  }

  //&__thumbs {
  //  font-size:   0;
  //  margin-left: -10px;
  //}
  //
  //&__thumb {
  //  display:        inline-block;
  //  vertical-align: top;
  //  padding-left:   10px;
  //  padding-top:    10px;
  //  width:          percentage(1/$product-photo-thumbs-number);
  //
  //  &-item {
  //    position: relative;
  //    border:   1px solid $product-photo-border-color;
  //    display:  block;
  //    height:   $product-photo-thumb-height;
  //    opacity:  .5;
  //
  //    &[data-product-photo-thumb-active] {
  //      opacity: 1;
  //    }
  //  }
  //
  //  &-img {
  //    @include absolute-center-translate;
  //    display:    block;
  //    padding:    5px;
  //    max-height: $product-photo-thumb-height;
  //  }
  //}

  //&__wrapper {
  //  position: absolute;
  //}
  &__zoom {
    position:   absolute;
    top:        -1px;
    left:       calc(100% + 1rem);
    z-index:    1000;

    font-size:  0;

    display:    inline-block;
    overflow:   hidden;

    background: #FFF;
    border:     5px solid #C7C7C7;

    & > * {
      display:    inline-block;
      width:      rem($product-photo-zoom-width);
      height:     rem($product-photo-zoom-height);
      max-height: 100%;
    }

    &:hover {
      display: none;
    }
  }
}