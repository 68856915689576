.card-of-kit {

  &__table {
    display: table;
  }
  &__cell {
    display:        table-cell;
    vertical-align: top;
    box-sizing:     content-box;

    &:not(:last-child) {
      padding-right: 1em;
    }
  }

  &__photo {
    width: em(70);
  }
  &__brand {
    font-size:      em(12);
    text-transform: uppercase;
    color:          $secondary-font-color;
  }
  &__title {
    font-size: em(13);
    height:    em(37, 13);
    overflow:  hidden;
    color:     $strong-font-color;
  }
  &__price {

  }

  @media (min-width: $screen-md-min) {
    max-width: em(250);
  }
}