.product-cut {

  font-size: 1rem;
  position: relative;

  &__container {
    padding: em(10px) em(10px) 0;
    background: $product-cut-background;

    border: 1px solid transparent;

    transition: all $theme-effects-time;

    &:not(:last-child) {
      border-bottom: 0;
    }
    &:not(:first-child) {
      border-top: 0;
    }
    &:last-child {
      padding-bottom: em(25px);
    }
  }
  &:hover &__container {
    border-color: $base-border-color;
    box-shadow: 0 5px 10px rgba(#000, 0.1);
  }

  &__container--hidden {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    //transform: translateY(-100%);

    visibility: hidden;
    opacity: 0;
  }
  &:hover &__container--hidden {
    visibility: visible;
    opacity: 1;
    //transform: translateY(0);

    transition: all $theme-effects-time;
  }

  &__row {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 15px;

      &--smaller {
        margin-bottom: 8px;
      }
    }
    &--actions {
      font-size: 0.9em;
    }

    &--flex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      margin-left: - 0.5em;
      margin-top: - 0.5em;
      & > * {
        margin-left: 0.5em;
        margin-top: 0.5em;
      }
    }
  }

  &__photo {
    position: relative;
    margin-bottom: rem(8px);
  }

  &__info {
    padding: 0 rem(15);
  }

  &__brand {
    font-size: em(12);
    color: $secondary-font-color;
    text-transform: uppercase;
  }

  &__title {

    height: em(37);
    overflow: hidden;

    color: $strong-font-color;
    transition: color $theme-effects-time;

    &:hover {
      color: $theme-accent-color--lighten;
    }

  }

  &__link {
    @include link-main();
    @extend .link--inherited;
  }

  /* Sale */
  &__sale {
    display: block;
    padding: 5px 0;
    color: #C00;
  }
  &__sale-item {
    &:not(:first-child) {
      margin-top: 3px;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    vertical-align: middle;

    font-size: rem(12);
    margin: - em(5px);

    & > * {
      padding: em(5px);
    }

  }

  &__variants {
    flex: 1 0 em(125);
  }

  /* Purchase */
  &__purchase {

    &-wrapper {
      display: block;
      @include clearfix;
    }

    &-quantity {
      float: left;
      margin-right: 10px;
      width: 105px;
      margin-top: 10px;
    }

    &-btn {
      float: left;
    }

  }

  &__not-available {
    margin-top: 10px;

    &-info {
      font-size: 0.9em;
      color: #000;
    }

    &-notify {
      margin-top: 2px;
    }

    &-link {
      @include link-main;
      @include link-js;
    }
  }

  /*  Short Description */
  &__desc {
    margin-top: 10px;
    font-size: 0.9em;
  }

  /*  Main Params */
  &__params {
    margin-top: 10px;
    font-size: 0.9em;

    &-item {
      display: table;
      width: 100%;
      padding: 5px 0;

      &:not(:first-child) {
        border-top: 1px solid $base-border-color;
      }
    }

    &-key {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      padding-right: 10px;
    }

    &-val {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      text-align: right;
    }
  }

  /*  Product is archived */
  &__archive {
    margin-top: 15px;
    display: inline-block;
    padding: 5px 20px;
    background: $theme-secondary-color;
    color: #000;
    font-size: 1.2rem;
  }

  &__delete {
    position: absolute;
    top: em(10px);
    right: em(10px);
  }

  &__bonus {
    margin-top: 5px;
  }
}