.thumbs-slider {
  $thumbs-visible-count: 4;
  $arrow-width: 4em;

  font-size: 1rem;

  position: relative;

  &__list {
    margin-left: $arrow-width/2;
    margin-right: $arrow-width/2;
  }

  &__item {
    float: left;
    width: percentage(1/$thumbs-visible-count);

    // hide elements before slick had been initialized
    &:not(.slick-slide):nth-child(n + #{$thumbs-visible-count}) {
      display: none;
    }
  }
  &__photo {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: $product-photo-height-to-width-ratio;

    transition: opacity $theme-effects-time;

    &[data-product-photo-thumb-active] {
      opacity: 0.5;
    }
  }
  &__imagebox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0.3em;
    padding: 0.3em;

    background: $theme-main-bg;
  }
  &__img {
    @include absolute-center-margin;
    max-height: 100%;
  }
  &__arrow {
    position: absolute;
    width: $arrow-width/2;
    top: 0;
    bottom: 0;

    transition: all $theme-effects-time;

    &--prev {
      left: 0;
    }
    &--next {
      right: 0;
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }
    &:not(.slick-disabled):hover {
      color: $theme-accent-color--darken;
      cursor: pointer;
    }
    &:not(.slick-disabled):active {
      transform: scale(1.2);
    }
  }
  &__icon {
    @include absolute-center-margin;
    display: block;
    width: 1.5em;
    height: 1.5em;
  }
  &__arrow--prev &__icon {
    margin-left: 0;
  }
  &__arrow--next &__icon {
    margin-right: 0;
  }

  @media (min-width: $screen-md-min) {
    &__list {
      margin-left: $arrow-width;
      margin-right: $arrow-width;
    }
    &__arrow {
      width: $arrow-width;
    }
  }
}