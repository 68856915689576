.filter {

  & .disabled {
    color: $secondary-font-color;
  }

  &__item {
    border-top: 0;
    padding: 10px;
    background: $theme-secondary-bg;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  /* Property header BEGIN */

  &__header {
    display: table;
    width: 100%;
    color: $sidebar-title-color;

    //make whole header clickable. Using if property has dropDown type
    &[data-filter-drop-handle] {
      cursor: pointer;
    }
  }

  &__title {
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
  }

  &__handle {
    position: relative;
    display: table-cell;
    width: 20px;
    text-align: right;
    vertical-align: middle;

    cursor: pointer;
  }
  &__icon {
    @include absolute-center-margin();
    width: rem(10px);
    height: rem(10px);

    opacity: 1;
    visibility: visible;

    transition: all $theme-effects-time;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
    &--plus.is-hidden {
      transform: rotate(90deg);
    }
    &--minus.is-hidden {
       transform: rotate(-90deg);
    }
  }

  &__handle-icon {
    display: block;

    /* to prevent mismatch in title sizes between properties with dropdown and without*/
    @include position(absolute, -4px 0 null null);

    &--plus {
    }

    &--minus {
    }
  }

  /* END Property header */

  &__inner {
    margin: 0.8em 0;

    &--js-hidden {
      display: none;
    }

    &--scroll {
      max-height: 180px;
      overflow: auto;
      padding-right: 5px;
    }
  }

  &__checkgroup {
    & + & {
      margin-top: em(10px);
    }
  }

  &__toggle-button {
    width: 100%;
  }

}