.logo {
  text-align: center;

  &__link {
    display: block;
  }
  &__img {
    display:    inline-block;
    max-width:  100%;
    max-height: 100%;
  }
  &__title {
    font-size:   rem(20px);
    font-weight: 700;
  }
}