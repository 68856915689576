.blog-subcats {
  $hor-gutter: 16px;
  $ver-gutter: 8px;


  &__list {
  }
  &__item {
    display: block;
    color: $strong-font-color;

    &:not(:first-child) {
      margin-top: - em($ver-gutter);
    }
  }

  &__author {
    text-transform: uppercase;
    color: $secondary-font-color;
  }
  &__link {
    display: block;
    padding: em($ver-gutter) rem($hor-gutter);
    text-decoration: none;
    color: inherit;

    &:hover {
      background: $theme-secondary-bg;
    }
  }
}