.product-purchase {
  $col-top-gutter: 10px;
  $col-left-gutter: 10px;

  // layouts
  &__row {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }

    &--flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row wrap;

      margin-top: - em($col-top-gutter);
      margin-left: - em($col-left-gutter);
    }
  }
  &__col {
    margin-top: em($col-top-gutter);
    margin-left: em($col-left-gutter);

    &--top {
      justify-content: flex-start;
    }

    &--basis-300 {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: em(300px);
    }
  }

  // elements
  &__label {
    width: em($product-quantity-width--lg + $col-left-gutter);
    margin-right: em(10);
  }

  @media (min-width: $screen-sm-min) {
    &__row {
      &--xs-nowrap {
        flex-wrap: nowrap !important;
      }
    }
  }
  @media (min-width: $screen-md-min) {
    &__row {
      &--md-nowrap {
        flex-wrap: nowrap !important;
      }
    }
  }
  @media (min-width: $screen-lg-min) {
    &__row {
      &--lg-nowrap {
        flex-wrap: nowrap !important;
      }
    }
  }
}