.contacts {
	$item-gutter: 45px;

	&__list {
		@media (min-width: $screen-md-min){
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			margin-left: - rem($item-gutter);
			margin-right: - rem($item-gutter);
		}

	}

	&__item {

		&:not(:first-child){
			padding-top: 20px;
		}
		@media (min-width: $screen-md-min){
			padding: 0 rem($item-gutter);
		}

	}

}


.contacts-info {
	display: table;

	&__cell {
		display: table-cell;
		vertical-align: top;

		& + & {
			padding-left: em(10px);
		}
	}

	&__icon {
		position: relative;
		display: block;
		width: 34px;
		height: 34px;
		border: 2px solid $base-font-color;
		border-radius: 50%;

		svg {
			@include absolute-center-margin();
			display: block;
			width: 50%;
			height: 50%;
		}
	}
	&__title {
		font-weight: 700;
		font-size: rem(13);
		color: $strong-font-color;
		margin-bottom: em(5px);
	}
	&__text {

	}

}