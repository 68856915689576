.header-toolbar {

  //Elements
  &__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__item {

    &--relative {
      position: relative;
    }

    &--hidden {
      display: none;
    }
  }

  &__icon {
    display: block;
    width: rem(8);
    height: rem(8);
  }

  @media (min-width: $screen-sm-min) {}
  @media (min-width: $screen-md-min) {
    &__item {
      &--md-visible {
        display: block;
      }
    }
  }
  @media (min-width: $screen-lg-min) {}
}