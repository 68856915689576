.intro-tab {

  transition: height 1s;

  &__list {
    display:         flex;
    justify-content: flex-start;
    flex-direction:  column;
  }
  &--tabs &__list {
    flex-direction: row;
    align-items:    stretch;
  }

  &__item {
    display: block;
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
  &--tabs &__item {
    margin-bottom: 0;
  }

  &__button {
    display:             flex;
    justify-content:     space-between;
    align-items:         center;
    padding:             0.75em 2.5em;
    width:               100%;

    text-decoration:     none;
    text-align:          center;
    font-weight:         700;
    color:               $strong-font-color;
    border:              1px solid $base-border-color;
    background:          $theme-secondary-bg;

    transition-property: color, background, border;
    transition-duration: $theme-effects-time;

    &:hover {
      background: $theme-main-bg;
    }

    &.is-active {
      border-bottom-color: $theme-main-bg;
      color:               $theme-accent-color;
      background:          $theme-main-bg;
    }
  }
  &--tabs &__button {
    display: flex;
    width:   auto;
    height:  100%;
  }
  &--tabs &__item:not(:first-child) &__button {
    border-left: 0;
  }

  &__icon {
    display:    block;
    width:      1em;
    height:     1em;

    transition: transform $theme-effects-time;
  }
  &--tabs &__icon {
    display: none;
  }
  &__button.is-active &__icon {
    transform: rotate(90deg);
  }

  &__content {
    margin-top: -1px;
    border:     1px solid $base-border-color;
    padding:    1em;

    transition: all $theme-effects-time;

    &:not(.is-active) {
      display: none;
    }
  }
  &:not(&--tabs) &__content {
    border-top: 0;
  }

}