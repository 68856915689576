/*----- LINK -----*/
@mixin link($color, $color--hover,
            $decoration:none, $decoration--hover:underline,
            $time: $theme-effects-time) {

  color:           $color;
  text-decoration: $decoration;

  transition:      all, $time;

  &:hover {
    color:           $color--hover;
    text-decoration: $decoration--hover;
  }
}


// link primary
@mixin link-main {
  @include link($base-main-link-color, $base-main-link-color--hover,
                        $base-main-link-decor, $base-main-link-decor--hover);
}


// link second
@mixin link-second {
  @include link($base-second-link-color, $base-second-link-color--hover,
                        $base-second-link-decor, $base-second-link-decor--hover);
}


// link inverse main
@mixin link-inverse-main {
  @include link($inverse-link-color, $inverse-link-color--hover,
                        $inverse-link-decor, $inverse-link-decor--hover);
}


// link inverse main
@mixin link-inverse-second {
  @include link($inverse-second-link-color, $inverse-second-link-color--hover,
                        $inverse-second-link-decor, $inverse-second-link-decor--hover);
}


// decorate on hover
@mixin link-decoration-only {
  @include link(inherit, inherit);
}


@mixin link-js {
  text-decoration:     none;
  border-bottom-style: dashed;
  border-bottom-width: 1px;

  &:hover {
    text-decoration: none;
  }
}


//Link empty, not clickable
@mixin link-empty {
  &, &:hover {
    color:           inherit;
    text-decoration: none;
    cursor:          default;
    pointer-events:  none;
  }
}

@mixin link-reset() {
  text-decoration: none;
  color: inherit;
}

/*----- /LINK -----*/

.link {
  @include link-reset();

  &--main {
    @include link-main;
  }

  &--secondary {
    @include link-second;
  }

  &--decorated {
    @include link-decoration-only;
  }

  &--inverse-main {
    @include link-inverse-main;
  }

  &--empty {
    @include link-empty;
  }
  &--js {
    @include link-js;
  }

  &--inherited {
    color: inherit;
  }

  &__icon {
    display:        inline-block;
    vertical-align: middle;
    width:          0.6em;
    height:         0.6em;
  }
}