.flipper {

  &__body {
    position: relative;
  }
  &__front {
  }
  &__back {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @at-root .product-cut:hover &__back,
  &:hover &__back {
    z-index: 2;
  }

  @supports (transition: rotateY) {
    perspective: 1000px;

    &__body {
      transition: transform $theme-effects-time*2;
      transform-style: preserve-3d;

      will-change: transform;
    }
    @at-root .product-cut:hover &__body,
    &:hover &__body {
      transform: rotateY(180deg);
    }

    &__back,
    &__front {
      backface-visibility: hidden;
    }
    &__front {
      transform: rotateY(0deg);
    }
    &__back {
      transform: rotateY(180deg);
    }
    @at-root .product-cut:hover &__back,
    &:hover &__back {
      z-index: auto;
    }
  }
}