.content {

  &--center {
    padding-top: 50px;
    text-align: center;
  }

  &__container {
    // extending bootstrap container
    // using when full width frames inside container are needed and there are no page__container active!
    @extend .container;
  }
  &__row {
    margin-bottom: em(60px);

    &:last-child {
      margin-bottom: 0;
    }

    &--sm {
      margin-bottom: 20px;
    }
    &--md {
      margin-bottom: 40px;
    }
  }
  &__col {
  }

  /* Pages header and H1 title group */
  &__header {

    margin-bottom:   em(20);

    &--flex {
      display:         flex;
      justify-content: space-between;
      flex-flow:       wrap;
      align-items:     center;

      margin-left:     -5px;
      margin-right:    -5px;

      & > * {
        margin: rem(5px);
      }
    }
  }
  &__title {
    font-size:   rem(23);
    font-weight: 700;
    line-height: 1.1;
    color:       $strong-font-color;
  }
  &__subtitle {
    color:         $secondary-font-color;
    font-size:     em(12);
    margin-bottom: em(8);
  }

  &__link {
  }

  &__hinfo {
    font-size:   0.9em;
    margin-top:  em(10);
    white-space: nowrap;
  }

  /* Main banner wrapper */
  &__banners {
    // delete content top gutter only if banner on the page
    margin-top:    - $page-content-top-gutter - 1px;

    margin-bottom: 42px;
    //background:    $main-banner-bg;
  }

  // decorators
  &__secondary {
    color: $secondary-font-color;
  }
  &__strong {
    font-weight: 700;
    color:       $strong-font-color;
  }

  /* Content pagination wrapper */
  &__pagination {
    margin-top: 45px;
    text-align: center;
    //background: $theme-secondary-color;
  }

  /* Content sidebar */
  &__sidebar {
    margin-bottom: rem(20);
  }

  @media (min-width: $screen-sm-min) {
    &__header {
      flex-wrap: nowrap;
    }
  }
  @media (min-width: $screen-md-min) {
    //&__toolbar {
    //  flex-shrink: 1;
    //  max-width: 33%;
    //}
    &__sidebar {
      margin-bottom: 0;
    }
  }
  @media (min-width: $screen-lg-min) {
  }

}