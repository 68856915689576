.discount-info {

  &__title {
    display: inline-block;
    margin-right: 1em;
  }
  &__value {
    display: inline-block;
    font-weight: 700;
  }

}