// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$checkbox-active-name: 'checkbox-active';
$checkbox-active-x: 0px;
$checkbox-active-y: 0px;
$checkbox-active-offset-x: 0px;
$checkbox-active-offset-y: 0px;
$checkbox-active-width: 16px;
$checkbox-active-height: 16px;
$checkbox-active-total-width: 48px;
$checkbox-active-total-height: 48px;
$checkbox-active-image: '../_img/sprite.png';
$checkbox-active: (0px, 0px, 0px, 0px, 16px, 16px, 48px, 48px, '../_img/sprite.png', 'checkbox-active', );
$checkbox-disabled-name: 'checkbox-disabled';
$checkbox-disabled-x: 16px;
$checkbox-disabled-y: 0px;
$checkbox-disabled-offset-x: -16px;
$checkbox-disabled-offset-y: 0px;
$checkbox-disabled-width: 16px;
$checkbox-disabled-height: 16px;
$checkbox-disabled-total-width: 48px;
$checkbox-disabled-total-height: 48px;
$checkbox-disabled-image: '../_img/sprite.png';
$checkbox-disabled: (16px, 0px, -16px, 0px, 16px, 16px, 48px, 48px, '../_img/sprite.png', 'checkbox-disabled', );
$checkbox-empty-name: 'checkbox-empty';
$checkbox-empty-x: 0px;
$checkbox-empty-y: 16px;
$checkbox-empty-offset-x: 0px;
$checkbox-empty-offset-y: -16px;
$checkbox-empty-width: 16px;
$checkbox-empty-height: 16px;
$checkbox-empty-total-width: 48px;
$checkbox-empty-total-height: 48px;
$checkbox-empty-image: '../_img/sprite.png';
$checkbox-empty: (0px, 16px, 0px, -16px, 16px, 16px, 48px, 48px, '../_img/sprite.png', 'checkbox-empty', );
$checkbox-hover-name: 'checkbox-hover';
$checkbox-hover-x: 16px;
$checkbox-hover-y: 16px;
$checkbox-hover-offset-x: -16px;
$checkbox-hover-offset-y: -16px;
$checkbox-hover-width: 16px;
$checkbox-hover-height: 16px;
$checkbox-hover-total-width: 48px;
$checkbox-hover-total-height: 48px;
$checkbox-hover-image: '../_img/sprite.png';
$checkbox-hover: (16px, 16px, -16px, -16px, 16px, 16px, 48px, 48px, '../_img/sprite.png', 'checkbox-hover', );
$-russia-name: 'Russia';
$-russia-x: 32px;
$-russia-y: 0px;
$-russia-offset-x: -32px;
$-russia-offset-y: 0px;
$-russia-width: 16px;
$-russia-height: 16px;
$-russia-total-width: 48px;
$-russia-total-height: 48px;
$-russia-image: '../_img/sprite.png';
$-russia: (32px, 0px, -32px, 0px, 16px, 16px, 48px, 48px, '../_img/sprite.png', 'Russia', );
$-ukraine-name: 'Ukraine';
$-ukraine-x: 32px;
$-ukraine-y: 16px;
$-ukraine-offset-x: -32px;
$-ukraine-offset-y: -16px;
$-ukraine-width: 16px;
$-ukraine-height: 16px;
$-ukraine-total-width: 48px;
$-ukraine-total-height: 48px;
$-ukraine-image: '../_img/sprite.png';
$-ukraine: (32px, 16px, -32px, -16px, 16px, 16px, 48px, 48px, '../_img/sprite.png', 'Ukraine', );
$-united-states-name: 'United-States';
$-united-states-x: 0px;
$-united-states-y: 32px;
$-united-states-offset-x: 0px;
$-united-states-offset-y: -32px;
$-united-states-width: 16px;
$-united-states-height: 16px;
$-united-states-total-width: 48px;
$-united-states-total-height: 48px;
$-united-states-image: '../_img/sprite.png';
$-united-states: (0px, 32px, 0px, -32px, 16px, 16px, 48px, 48px, '../_img/sprite.png', 'United-States', );
$spritesheet-width: 48px;
$spritesheet-height: 48px;
$spritesheet-image: '../_img/sprite.png';
$spritesheet-sprites: ($checkbox-active, $checkbox-disabled, $checkbox-empty, $checkbox-hover, $-russia, $-ukraine, $-united-states, );
$spritesheet: (48px, 48px, '../_img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
