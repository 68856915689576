.breadcrumbs {

  $separator-ico: '>';
  $separator-color: $strong-font-color;
  $vertical-gutter: 5px;

  margin-top: (-$vertical-gutter);
  color:      $secondary-font-color;

  @media(min-width: $screen-md-min) {
    font-size: em(12);
  }


  &__item {
    display:    inline-block;
    margin-top: $vertical-gutter;
  }

  &__link {
  }

  &__icon {
    display:        inline-block;
    vertical-align: middle;
    height:         0.6em;
    width:          0.6em;
  }

}