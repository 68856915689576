.filter-price {

  &__row {

    & + & {
      margin-top: em(15);
    }

    &--flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: - em(10);
      margin-right: - em(10);
    }
  }
  &__cell {
    padding: 0 em(10px);
    width: 50%;
  }

  &__form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__label {
    margin-right: em(5);
    //@extend .input-group-addon;
  }

  &__input {
    @extend .form-control;
    width: 75px;
  }

}