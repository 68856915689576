.quick-view {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(#fff, 0.4);

  &__btn {
    @include absolute-center-translate;
  }

}