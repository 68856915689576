//Bootstrap variables and mixins
$output-bourbon-deprecation-warnings: false; //omit bourbon deprecation warnings in terminal
@import "bourbon";

//Project variables and mixins
@import "_core/variables.scss";
@import "_core/mixins.scss";
@import "_core/animations.scss";

//Project Reset
@import "_core/reset.scss";

// Project vendors
@import "_vendor/vendor";

/*
--------------------------------------------------
Project Components
--------------------------------------------------
*/
@import "_core/base.scss";

// atoms
@import "icons/sprite.scss";
@import "icons/icons.scss";
@import "svg/svg-icon.scss";
@import "buttons/buttons.scss";
@import "link/link";

// layouts
@import "scaffolding/page.scss";
@import "scaffolding/content.scss";
@import "scaffolding/footer.scss";
@import "scaffolding/header";
@import "scaffolding/headline";
@import "scaffolding/sidebar";

@import "offline/offline.scss";

@import "frames/frame-content/frame-content.scss";

@import "grid/grid.scss";
@import "typo/typo.scss";

@import "navs/list-nav/list-nav.scss";
@import "navs/tree-nav/tree-nav.scss";
@import "navs/table-nav/table-nav.scss";
@import "navs/cols-nav/cols-nav.scss";
@import "navs/mobile-nav/mobile-nav.scss";
@import "navs/sidebar-nav/sidebar-nav.scss";
@import "navs/overlay/overlay.scss";
@import "navs/blog-subcats/blog-subcats";

@import "header-toolbar/header-toolbar";
@import "user-panel/user-panel";

@import "modal/modal.scss";

@import "helpers/site-info/site-info.scss";
@import "helpers/soc-groups/soc-groups.scss";
@import "helpers/pay-icons/pay-icons.scss";
@import "helpers/star-rating/star-rating.scss";
@import "helpers/star-voting/star-voting.scss";
@import "helpers/messages/messages.scss";
@import "helpers/likes/likes.scss";
@import "helpers/shares/shares.scss";
@import "helpers/tooltip/tooltip.scss";
@import "helpers/loaders/loader-frame.scss";
// @import "helpers/loaders/spinner-fa/spinner-fa.scss";
@import "helpers/loaders/spinner-circle/spinner-circle.scss";
@import "helpers/checkgroup/checkgroup";
@import "helpers/radiogroup/radiogroup";
@import "helpers/remove-btn/remove-btn";
@import "helpers/sizes-table/sizes-table";
@import "helpers/flipper/flipper";
@import "helpers/quick-view/quick-view";

@import "benefits/benefits.scss";
@import "banners/banner-simple/banner-simple.scss";
@import "banners/_banners.scss";
@import "navs/board-nav/board-nav.scss";
@import "widgets/widget-primary/widget-primary.scss";
@import "widgets/widget-secondary/widget-secondary.scss";
@import "widgets/widget-sidebar/widget-sidebar.scss";
@import "widgets/news-widget/news-widget";
@import "post/post.scss";
@import "small-post/small-post";
@import "brands/bradns-widget/brands-widget.scss";
@import "brands/brands-images/brands-images.scss";
@import "brands/brand-sidebar/brand-sidebar";
@import "seo-text/seo-text.scss";
@import "breadcrumbs/breadcrumbs.scss";
@import "paginator/paginator.scss";
@import "comments/comments.scss";

@import "form/form.scss";
@import "form/form-input.scss";
@import "form/autocomplete/autocomplete.scss";
@import "form/range-slider/range-slider.scss";

@import "catalog/catalog-toolbar/catalog-toolbar.scss";
@import "catalog/filter/filter.scss";
@import "catalog/active-filters/active-filters.scss";
@import "catalog/catalog-section/catalog-section.scss";
@import "catalog/filter-price/filter-price";

@import "product/product-cut/product-cut.scss";
@import "product/product-thumb/product-thumb.scss";
@import "product/product-intro/product-intro.scss";
@import "product/product-photo/product-photo.scss";
@import "product/product-variants/variants-radio/variants-radio.scss";
@import "product/product-variants/variants-select/variants-selct";
@import "product/properties/properties.scss";
@import "product/product-price/product-price.scss";
@import "product/product-kit/product-kit.scss";
@import "product/product-kit/card-of-kit";
@import "product/product-shipping/product-shipping.scss";
@import "product/product-fullinfo/product-fullinfo.scss";
@import "product/product-sales/product-sales";
@import "product/product-actions/product-actions";
@import "product/product-purchase/product-purchase";
@import "product/product-buy/product-buy";
@import "product/thumbs-slider/thumbs-slider";

@import "cart/cart-frame/cart-frame.scss";
@import "cart/cart-summary/cart-summary.scss";
@import "cart/delivery-radio/delivery-radio.scss";
@import "cart/cart-prod-table/cart-prod-table";
@import "cart/cart-product/cart-product.scss";
@import "cart/order-details/order-details.scss";
@import "cart/cart-price/cart-price.scss";
@import "cart/cart-total/cart-total";
@import "cart/cart-subtotal/cart-subtotal";
@import "cart/discount-drop-down/discount-drop-down";
@import "cart/cart-delivery/cart-delivery";
@import "cart/cart-payments/cart-payments";

@import "profile/discount-info/discount-info.scss";
@import "profile/discount-table/discount-table.scss";

@import "compare/compare.scss";

@import "gallery/gallery-album/gallery-album.scss";
@import "gallery/gallery-image/gallery-image.scss";
@import "gallery/gallery-menu/gallery-menu.scss";

@import "countdown/countdown-post";
@import "countdown/countdown-product";

@import "logo/logo";
@import "toolbar-element/toolbar-element";

@import "contacts/contacts";
@import "blog-widget/blog-widget";

@import "info-table/info-table";
@import "intro-tab/intro-tab";

@import "tags-cloud/tags-cloud";

@import "product-sizes/product-sizes";