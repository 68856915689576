.product-sizes {
  background-color: #fff;
  max-width: 750px;
  margin: 15% auto;

  &__link-modal {
    display: inline-block;
    font-weight: 500;
    color: #666666;
    padding: 10px 20px;
    border: 1px solid #888888;
    border-radius: 2px;
    text-decoration: none;
    margin: 20px 10px;

    &:hover,
    &:focus {
      background-color: #efefef;
    }
  }
  
  .modal__close-button {
    background-color: #EFEFEF;

    &:hover {
      color: #666666;
    }
  }

  .modal__content {
    @media (min-width: $screen-md-min) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &__table {
    width: 100%;
    background-color: #F2F2F2;
    text-align: center;
    font-size: 13px;
    border-collapse: separate;
    border-spacing: 2px;

    th {
      font-weight: bold;
      padding: 15px 10px;
      color: #666666;
    }
    td {
      padding: 10px;
      background-color: #fff;
      color: #000000;
    }

    @media (max-width: $screen-xs) {
      th {
        padding-left: 2px;
        padding-right: 2px;
      }
      td {
        padding: 5px;
      }
    }
  }

  &__container {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
}