.radiogroup {
  $radio-size: 1em;

  &__input {
    display: none;
  }

  &__label {
    display:     flex;
    align-items: flex-start;
    flex-wrap:   nowrap;

    padding:     0.75em 1em;
  }
  &__input:checked + &__label {
    background: $theme-secondary-bg;
  }

  &__radio {
    flex-shrink:   0;

    position:      relative;
    display:       block;
    width:         $radio-size;
    height:        $radio-size;
    border:        1px solid $base-border-color;
    border-radius: 50%;
    background:    $theme-main-bg;

    margin-right:  1em;

    transition:    border-color $theme-effects-time;

    &:before {
      display:       block;
      content:       "";
      @include absolute-center-margin;
      width:         50%;
      height:        50%;

      border-radius: 50%;
      background:    transparent;

      transition:    all $theme-effects-time;
    }
  }
  &__input:checked + &__label &__radio {
    &:before {
      background: $theme-accent-color;
    }
  }

  &:hover &__radio,
  &__input:checked + &__label &__radio {
    border-color: $theme-accent-color;
  }

  &__content {
    flex-grow: 1;
  }
}