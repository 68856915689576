.remove-btn {
  $remove-btn-size: 1.5em;

  position: relative;
  display: block;

  width: $remove-btn-size;
  height: $remove-btn-size;

  border: 1px solid $base-border-color;
  background: $theme-main-bg;
  color: $strong-font-color;

  transition: all $theme-effects-time;

  &--gray {
    background: $theme-secondary-bg;
  }

  &:hover {
    border-color: $theme-main-color;
    background: $theme-main-color;
    color: $inverse-font-color;
  }

  &:active {
    transform: scale(1.1);
  }

  &__icon {
    @include absolute-center-margin();

    width: 50%;
    height: 50%;
  }
}