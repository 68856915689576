.banner-simple {
  $border-color: $base-border-color;
  $title-color: $strong-font-color;
  $desc-color: $strong-font-color;
  $dots-bg: rgba(#ddd, 0.2);
  $dots-bg-active: $theme-main-bg;
  $dots-border: rgba($theme-main-bg, 0.3);
  $dots-border-active: $theme-main-bg;
  $arrow-bg: transparent;
  $arrow-bg-hover: transparent;
  $arrow-color: $dots-bg;
  $arrow-color-hover: $dots-bg-active;

  position:  relative;
  font-size: 1rem;
  overflow:  hidden;

  /* Only one banner is visible while js is loading */
  [data-slider-nojs] {
    [data-slider-slide]:not(:first-child) {
      display: none;
    }
  }

  &__item {
    position: relative;
  }

  &__link {
    @include position(absolute, 0 0 0 0);
    opacity:          0;
    z-index:          $z-index-s;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0);
  }

  &__image {
    display: block;
    margin:  auto;
    width:   100%;
  }

  &__content {
    text-align:  center;
    @include position(absolute, 20px 0 null 0);
    padding:     10px;
    color:       $desc-color;
    line-height: 1.1;
    font-weight: bold;

    @media(min-width: $screen-md-min) {
      font-size: 1.2em;
    }

  }

  &__arrow {
    position:   absolute;
    width:      rem(40px);
    top:        0;
    bottom:     0;
    z-index:    $z-index-s;
    cursor:     pointer;
    color:      $arrow-color;

    transition: color $theme-effects-time;

    &:hover {
      color: $arrow-color-hover;
    }

    &.slick-disabled {
      display: none !important;
    }

    &--prev {
      left: rem(10px);
    }

    &--next {
      right: rem(10px);
    }
  }

  &__icon {
    @include absolute-center-translate;

    display: block;
    width:   rem(20px);
    height:  rem(20px);
  }
  @media (min-width: $screen-sm-min) {
    &__icon {
      height: rem(30px);
    }
  }
  @media (min-width: $screen-md-min) {
    &__icon {
      height: rem(40px);
    }
  }
  @media (min-width: $screen-lg-min) {
    &__icon {
      height: rem(50px);
    }
  }

  .slick-dots {
    position:   absolute;
    bottom:     5%;
    width:      100%;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display:  inline-block;
    margin:   0 3px;
  }
  .slick-dots button {
    font-size:        0;
    line-height:      0;
    display:          block;
    width:            rem(16px);
    height:           rem(16px);
    border-radius:    50%;
    cursor:           pointer;
    color:            transparent;
    border:           2px solid $dots-border;
    background-color: $dots-bg;
  }
  .slick-dots .slick-active button {
    background-color: $dots-bg-active;
    border-color:     $dots-border-active;
  }
}