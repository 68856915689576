//--------------------------------------------------
//--------------------------------------------------
// Base
//--------------------------------------------------
//--------------------------------------------------

// Palette
$theme-main-color:                #272727;
$theme-main-color--lighten:       #3c3c3c;

$theme-accent-color--darken:      #885a19;
$theme-accent-color:              #c89347;
$theme-accent-color--lighten:     #e8ba78;

$theme-secondary-color:           #efefef;
$theme-secondary-color--dark:     #e5e5e5;

$attention-color:                 #d52b1e;
$info-color:                      rgba($theme-accent-color, 0.2);

$theme-main-bg:                   #fff;
$theme-secondary-bg:              $theme-secondary-color;

// Fonts
$base-font-family:                'Roboto', sans-serif;
$base-font-color:                 #888888;
$base-font-size:                  14px;
$base-line-height:                1.3;
$strong-font-color:               #000;
$secondary-font-color:            #9a9a9a;
$third-font-color--light:         #e0e0e0;

$inverse-font-color:              #ffffff;
$inverse-secondary-font-color:    #9a9a9a;

// Links
$base-main-link-color:            $theme-accent-color;
$base-main-link-color--hover:     $theme-accent-color--darken;
$base-main-link-decor:            none;
$base-main-link-decor--hover:     none;

$base-second-link-color:          $strong-font-color;
$base-second-link-color--hover:   $theme-accent-color;
$base-second-link-decor:          none;
$base-second-link-decor--hover:   none;

$inverse-link-color:              $inverse-font-color;
$inverse-link-color--hover:       $theme-accent-color;
$inverse-link-decor:              none;
$inverse-link-decor--hover:       none;

$inverse-second-link-color:              $inverse-font-color;
$inverse-second-link-color--hover:       $inverse-link-color;
$inverse-second-link-decor:              none;
$inverse-second-link-decor--hover:       underline;

// Helpers

$base-border-color:               #d9d9d9;
$focus-border-color:              rgba($theme-accent-color, 0.3);

$base-box-shadow-color:           rgba(0, 0, 0, 0.15);
$focus-box-shadow-color:          #ffe9ec;

$theme-effects-time:              0.3s; // animation time

// Bootstrap override
$border-radius-base: 0;

// Bourbon override
$em-base: $base-font-size;


//--------------------------------------------------
// Scaffolding
//--------------------------------------------------


// ----- Page ----- //
$page-content-top-gutter:         20px;
$page-fgroup-bg:                  $theme-main-color;
$page-ovarlay-z-index:            9999;

$page-mobile-width:               250px;
$page-mobile-bg:                  $theme-main-color;
$page-mobile-active-bg:           lighten($theme-main-color, 10%);
$page-mobile-color:               $inverse-font-color;
$page-mobile-speed:								0.3s;
$page-overlay-color:              rgba(11,11,11,0.6);


// ----- Headline ----- //
$headline-border-color:           $base-border-color;


// ----- Footer ----- //
$footer-border-color:             $theme-main-color--lighten;
$footer-text-color:               $inverse-font-color;
$footer-title-color:              $inverse-font-color;
$footer-link-color:               $inverse-font-color;
$footer-link-hover-color:         $theme-accent-color--lighten;
$footer-col-vertical-gutter:      20px;
$footer-col-horizontal-gutter:    40px;

// ----- soc-groups ----- //
$soc-group-colors: (
  vkontakte:     #54769B,
  facebook:      #3A5BA0,
  google-plus:   #D95032,
  twitter:       #2CA7E0,
  odnoklassniki: #F2720C,
  instagram:     linear-gradient(45deg, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 100%, #515ECF)
);
$soc-group-icon-size:       40px;
$soc-group-gutter:          10px;
$soc-group-border-radius:   0;
$soc-group-icon-color: $inverse-secondary-font-color;
$soc-group-border-color:    $theme-main-color--lighten;
$soc-group-bg:              transparent;
$soc-group-bg-hover:        rgba(#fff, 0.15);


// ------- user panel ------ //
$header-toolbar-icon-size:  22px;
$header-toolbar-icon-color: $theme-main-color;
$header-toolbar-bg-color:   $theme-main-bg;
$header-toolbar-padding:    12px;


//--------------------------------------------------
// Z Indexes
//--------------------------------------------------
$z-index-xs:  10;
$z-index-s:   100;
$z-index-m:   1000;
$z-index-l:   10000;
$z-index-xl:  100000;


//--------------------------------------------------
// Grid
//--------------------------------------------------
// Bootstrap override
$grid-gutter-width:               30px;
$screen-lg:                       1200px + $grid-gutter-width*2;
$container-large-desktop:         1200px + $grid-gutter-width;
$grid-col-mobile-spacer:          20px;




//--------------------------------------------------
// Forms
//--------------------------------------------------
// Bootstrap override

$input-group-addon-bg: 						#efefef;
$input-border:										$base-border-color;
$input-border-radius:             0;
$input-border-radius-small:       0;
$input-border-radius-large:       0;


//--------------------------------------------------
// Buttons
//--------------------------------------------------

//$btn-height:    34px;
//$btn-lg-height: 46px;

// ----- Bootstrap override ----- //
$btn-default-border:							$theme-main-color;
$btn-default-bg:                  $theme-main-color;
$btn-default-color:               $inverse-font-color;

$btn-border-radius-base:          0;
$btn-border-radius-small:         0;
$btn-border-radius-large:         0;

$btn-primary-bg: $theme-accent-color;
$btn-primary-border: $theme-accent-color;

// ----- Main button ----- //
$btn-main-bg:                     $theme-main-color;
$btn-main-bg-hover:               lighten($btn-main-bg, 20%);
$btn-main-color:                  #fff;
$btn-main-color-hover:            #fff;



//--------------------------------------------------
// Header site info
//--------------------------------------------------
$site-info-title-color:           $strong-font-color;
$site-info-desc-color:            $base-font-color;
$site-info-ico-color:             $theme-main-color;

//--------------------------------------------------
// Header autocomplete
//--------------------------------------------------
$autocomplete-shadow-color: $base-box-shadow-color;
$autocomplete-border-color: $base-border-color;
$autocomplete-width:        75vw;
$autocomplete-max-width:    400px;
$autocomplete-bg-color:     $theme-main-bg;


//--------------------------------------------------
// Header toolbar-element
//--------------------------------------------------
$toolbar-element-color:           $theme-main-color;
$toolbar-element-bg:              $theme-main-bg;
$toolbar-element-color--active:   $toolbar-element-bg;
$toolbar-element-bg--active:      $toolbar-element-color;
$toolbar-element-padding:         10px;
$toolbar-element-icon-size:       22px;
$toolbar-element-badge-size:      20px;
$toolbar-element-badge-font-size: 10px;
$toolbar-element-badge-color:     $toolbar-element-bg;
$toolbar-element-badge-bg:        $toolbar-element-color;



//--------------------------------------------------
// Overlay
//--------------------------------------------------
$overlay-bg-color:                $theme-main-bg;
$overlay-border-color:            $base-border-color;
$overlay-shadow-color:            $base-box-shadow-color;

$overlay-link-color:              $base-second-link-color;
$overlay-link-hover-color:        $theme-accent-color;

$overlay-link-background:         transparent;
$overlay-link-background-hover:   $theme-secondary-bg;


//--------------------------------------------------
// Navigation
//--------------------------------------------------

// ----- table nav ----- //
$table-nav-height:                40px;
$table-nav-bg-color:              $theme-main-bg;
$table-nav-bg-hover-color:        $theme-main-color;
$table-nav-bg-active-color:       $theme-main-bg;
$table-nav-link-color:            $strong-font-color;
$table-nav-link-hover-color:      $inverse-font-color;
$table-nav-border-color:          $base-border-color;

// ----- tree nav ----- //
$tree-nav-bg-color:               $theme-main-bg;
$tree-nav-border-color:           $base-border-color;
$tree-nav-shadow-color:           $base-box-shadow-color;

$tree-nav-link-color:             $base-second-link-color;
$tree-nav-link-hover-color: $base-second-link-color--hover;
$tree-nav-link-hover-bg:          $theme-main-bg;


// ----- cols nav ----- //
$cols-nav-bg-color:               #fff;
$cols-nav-border-color:           $base-border-color;
$cols-nav-shadow-color:           $base-box-shadow-color;
$cols-subnav-col-width:           200px;


// ----- mobile nav ----- //
$mobile-nav-slide-speed:          $page-mobile-speed;
$mobile-nav-border-color:         $theme-main-color--lighten;
$mobile-nav-link-color:           $inverse-font-color;
$mobile-nav-viewall-color: $inverse-secondary-font-color;
$mobile-nav-title-bg:             $theme-accent-color;
$mobile-nav-title-color:          $inverse-font-color;



// ----- board nav ----- //
$board-nav-bg-color:              $theme-secondary-color;
$board-nav-link-1-color:          #000;
$board-nav-link-1-color-hover:    #000;



//--------------------------------------------------
// Benefits
//--------------------------------------------------
$benefits-bg-color:               #fafafa;
$benefits-border-color:           $base-border-color;
$benefits-title-color:            #000;
$benefits-ico-color:              #000;
$benefits-desc-color:             #999;


//--------------------------------------------------
// main banner
//--------------------------------------------------
$main-banner-width: 1572px;
$main-banner-bg:    #09080e;


//--------------------------------------------------
// Widgets
//--------------------------------------------------

// ----- Widget primary ----- //
$widget-primary-header-bg:              $theme-secondary-color;
$widget-primary-title-color:            $strong-font-color;
$widget-primary-decorator-color:        $theme-accent-color;
$widget-primary-arrow-color:            $base-font-color;
$widget-primary-arrow-color-hover:      $theme-main-bg;
$widget-primary-arrow-background:       $theme-main-bg;
$widget-primary-arrow-background-hover: $theme-main-color;
$widget-primary-arrow-size:             42px;




// ----- Widget secondary ----- //
$widget-secondary-title-color:          $strong-font-color;
$widget-secondary-arrow-size:           42px;
$widget-secondary-arrow-color:            $base-font-color;
$widget-secondary-arrow-color-hover:      $theme-main-bg;
$widget-secondary-arrow-background:       $theme-main-bg;
$widget-secondary-arrow-background-hover: $theme-main-color;


// ----- Brands widget ----- //
$bradns-widget-border-color:        $base-border-color;

$bradns-widget-arrow-bg:            rgba(0, 0, 0, 0.129);
$bradns-widget-arrow-bg-hover:      rgba(0, 0, 0, 0.2);
$bradns-widget-arrow-color:         rgb(144, 144, 143);
$bradns-widget-arrow-color-hover:   rgb(144, 144, 143);

$bradns-widget-link-color:          rgba(0,0,0,1);
$bradns-widget-link-color-hover:    rgba(0,0,0,0.7);


// ----- Brands image ----- //
$bradns-image-height:         120px;


// ----- SEO text ----- //
$seo-text-title-color:          #000;


// ----- Breadcrumbs ----- //



// ----- Star rating ----- //
$star-voting-color:             #FFB300;
$star-rating-color:             #FFB300;


// ----- Message ----- //
$message-success-color:         #dff0d8;
$message-success-border:        #dff0d8;

$message-error-color:           #ffefe8;
$message-error-border:          #e89b88;

$message-info-color:            #FCF8E3;
$message-info-border:           #efe4ae;



//--------------------------------------------------
// Product
//--------------------------------------------------
$product-photo-border-color:   $base-border-color;


$product-photo-height-to-width-ratio: 95%;
$product-photo-max-fluid-width:       500px;

$product-photo-fix-height-xs:   70px;
$product-photo-fix-height-sm:   100px;
$product-photo-fix-height-md:   306px;
$product-photo-fix-height-lg:   400px;

$product-photo-zoom-width:  550px;
$product-photo-zoom-height: $product-photo-zoom-width * $product-photo-height-to-width-ratio/100%;

$product-photo-thumbs-number:   5;
$product-photo-thumb-height:    60px;

$product-photo-border-color: transparent;

$product-label-bg-default:         rgba(#7F7F7F, 0.8);
$product-label-bg-hit:             rgba(#7F7F7F, 0.8);
$product-label-bg-hot:             rgba(#7F7F7F, 0.8);
$product-label-bg-action:          rgba(#DE5648, 0.8);
$product-label-bg-discount:        rgba(#DE5648, 0.8);


$product-quantity-width:     50px;
$product-quantity-width--lg:  70px;

$product-cut-background:     $theme-main-bg;



//--------------------------------------------------
// Compare products
//--------------------------------------------------
$compare-product-height:            290px;
$compare-product-bg:                #f2f2f2;



//--------------------------------------------------
// Modal window
//--------------------------------------------------
$modal-size:                        500px;
$modal-size-sm:                     460px;
$modal-size-lg:                     800px;
$modal-top-padding:                 15px;
$modal-hor-padding:                 25px;
$modal-bottom-padding:              30px;
$modal-internal-gutter:             15px;

$modal-header-bg:                   #f2f2f2;
$modal-background:                  $theme-secondary-bg;
$modal-border-color:                $base-border-color;
$modal-header-color:                $strong-font-color;



//--------------------------------------------------
// Ajax Loaders
//--------------------------------------------------
$spinner-fa-size:                   45px;
$spinner-fa-color:                  #000;
$spinner-fa-image:                  '\f110';

$spinner-circle_speed:              .675s;
$spinner-circle_radius:             80%;
$spinner-circle_fill_radius:        90px;

$spinner-circle_size:               40px;
$spinner-circle_thickness:          10px;
$spinner-circle_inner_circle:       $spinner-circle_size - $spinner-circle_thickness;

$spinner-circle_color:              black;
$spinner-circle_bg:                 lightgray;
$spinner-circle_inner_bg:           white;


$mfp-image-background: #fff;
$mfp-controls-color: #fff;

//---------------------------------------------------
// sidebar
//---------------------------------------------------
$sidebar-background:        $theme-secondary-bg;
$sidebar-title-color:       $theme-accent-color;
$sidebar-top-gutter:        15px;
$sidebar-bottom-gutter:     20px;
$sidebar-horizontal-gutter: 15px;
