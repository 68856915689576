.toolbar-element {

  position: relative;
  display: table;
  padding: rem($toolbar-element-padding/2);

  text-decoration: none;

  background: $toolbar-element-bg;
  color: $toolbar-element-color;

  transition-property: background, color;
  transition-duration: $theme-effects-time;

  &:not(&--no-active):hover,
  &--active {
    background: $toolbar-element-bg--active;
    color: $toolbar-element-color--active;
  }
  &--no-active {
    cursor: default;
    pointer-events: none;
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;

    & + & {
      padding-left: rem($toolbar-element-padding/2);
    }

    &--hidden {
      display: none;
    }
  }

  &__icon {
    display: block;
    height: rem($toolbar-element-icon-size);
    width: rem($toolbar-element-icon-size);
  }

  &__title {
    font-weight: 700;
    font-size: 1.2em;
  }

  &__desc {
    white-space: nowrap;
  }

  &__badge {
    position: absolute;
    top: - rem(5px);
    right: - rem(5px);

    display: block;
    font-size: rem($toolbar-element-badge-font-size);

    width: em($toolbar-element-badge-size, $toolbar-element-badge-font-size);
    height: em($toolbar-element-badge-size, $toolbar-element-badge-font-size);
    line-height: em($toolbar-element-badge-size, $toolbar-element-badge-font-size);
    vertical-align: middle;
    text-align: center;

    border-radius: 50%;

    background: $toolbar-element-badge-bg;
    color: $toolbar-element-badge-color;
  }

  &__drop {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;

    visibility: hidden;
    opacity: 0;
    transition: visibility $theme-effects-time, opacity $theme-effects-time;
  }
  &:hover &__drop {
    visibility: visible;
    opacity: 1;
  }

  // media queries
  @media (min-width: $screen-sm-min) {
    padding: rem($toolbar-element-padding);
    &__badge {
      top: 0;
      right: 0;
    }
  }
  @media (min-width: $screen-md-min) {
    &__cell {
      &--md-visible {
        display: table-cell;
      }
    }
    &__badge {
      display: none;
    }
  }
  @media (min-width: $screen-lg-min) {
  }
}